import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NotifyStripeComponent} from "./notify-stripe.component";


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [NotifyStripeComponent],
    exports: [NotifyStripeComponent]
})

export class NotifyStripeModule {
}