/**
 * Created by jan.hroch on 27.06.2017.
 */
import {AfterViewInit, Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import {IDomainInfo, Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {IUnpaidInvoice} from "../index/common";

@Component({
    selector: 'cmp-unpaid-invoices',
    templateUrl: '../../tpl/unpaid-invoices.html',
    styleUrls: ['../../../assets/styles/2-components/unpaid-invoices.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UnpaidInvoicesComponent extends Translatable implements AfterViewInit{

    ngAfterViewInit(): void {
        this.isLoaded = true;
    }
    @Input() unpaidInvoices: IUnpaidInvoice[];
    @Output() showUnpaidInvoices: EventEmitter<boolean> = new EventEmitter();
    domainInfo: IDomainInfo;
    currencyCode: string;
    isLoaded = false;

    constructor(dataSvc: DataService, seSvc: SettingsService) {
        super(dataSvc, seSvc);
        this.domainInfo = seSvc.domainInfo;
        if (this.domainInfo.domainCode == "vo.cz" || this.domainInfo.domainCode == "vo.cz") {
            this.currencyCode = seSvc.settings.cultures.find(s => s.cultureId == 34).currencySymbol;
        }
        if (this.domainInfo.domainCode == "vo.sk" || this.domainInfo.domainCode == "vo.sk") {
            this.currencyCode = seSvc.settings.cultures.find(s => s.cultureId == 102).currencySymbol;
        }
        if (this.domainInfo.domainCode == "vo.eu") {
            this.currencyCode = seSvc.settings.cultures.find(s => s.cultureId == 50).currencySymbol;
        }

    }

    get isMobile(){
            // User agent string method
            let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

            // Screen resolution method
            if (!isMobile) {
                let screenWidth = window.screen.width;
                let screenHeight = window.screen.height;
                isMobile = (screenWidth < 768 || screenHeight < 768);
            }

            // Touch events method
            if (!isMobile) {
                isMobile = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
            }

            // CSS media queries method
            if (!isMobile) {
                let bodyElement = document.getElementsByTagName('body')[0];
                isMobile = window.getComputedStyle(bodyElement).getPropertyValue('content').indexOf('mobile') !== -1;
            }

            return isMobile;
    }

    get getUnpayedInvoicesPriceSum(){
        let sum = this.unpaidInvoices.reduce((accumulator, object) => {
            return accumulator + object.amountToPay;
        }, 0);

        return sum.toLocaleString();
    }
    closeUnpaidInvoices(): void {
        this.showUnpaidInvoices.emit(false);
    }
}