import {Component, Input} from '@angular/core';
import {ArticleMenuLinkSelector, Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Component({
    templateUrl: '../../tpl/article-main-menu.html',
    selector: 'cmp-article-main-menu'
})

export class ArticleMainMenuComponent extends Translatable {

    @Input() articleSectionId: number;

    articles: ArticleMenuLinkSelector[];
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, seSvc: SettingsService, private http: HttpClient) {
        super(dataSvc, seSvc);
    }

    getArticles(): void {
        this.http.get<ArticleMenuLinkSelector[]>(`api/article/getMainMenuLinks/${this.articleSectionId}`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                res => {
                    this.articles = res;
                }
            );
    }

    ngOnInit(): void {
        this.getArticles();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}