import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'units'})
export class UnitsPipe implements PipeTransform {

    constructor() {
    }

    transform(value: string): string {
        if (value == 'm2') {
            return 'm<sup>2</sup>';
        }
        if (value == 'm3') {
            return 'm<sup>3</sup>';
        }

        return value;
    }

}