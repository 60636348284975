import {IProductAmountSettings} from "../modules/product-amount/interfaces/IProductAmountSettings";
import {IProduct} from "../modules/product/common";
import {IProductList} from "../interfaces/general";
import {CartItemSelectorFull} from "../modules/cart/common";


export class ProductAmountAdapter implements IProductAmountSettings {

    constructor(product: IProduct) {
        try {
            this.Factor = product.PackageQty || 1;
            this.MinQuantity = product.MinQtyOrder || this.Factor;
            this.ProductId = product.Id;
            this.Unit = product.Unit;
        }
        catch {
            debugger;
        }
    }

    Factor: number;
    MaxQuantity: number;
    MinQuantity: number;
    ProductId: number;
    Unit: string;

}

export class ProductVariantAmountAdapter implements IProductAmountSettings {

    constructor(product: IProductList) {
        this.Factor = product.PackageQty || 1;
        this.MinQuantity = product.MinQtyOrder || this.Factor;
        this.ProductId = product.Id;
        this.Unit = product.Unit;
    }

    Factor: number;
    MaxQuantity: number;
    MinQuantity: number;
    ProductId: number;
    Unit: string;

}
export class CartItemAmountAdapter implements IProductAmountSettings {

    constructor(product: CartItemSelectorFull) {
        this.Factor = product.productDetail.PackageQty || 1;
        this.MinQuantity = product.productDetail.MinQtyOrder || this.Factor;
        this.ProductId = product.productDetail.Id;
        this.Unit = product.productDetail.Unit;
    }

    Factor: number;
    MaxQuantity: number;
    MinQuantity: number;
    ProductId: number;
    Unit: string;

}