import {Injectable} from "@angular/core";
import {AddressSelector, } from "./common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class AddressService {

    constructor(private http: HttpClient){

    }
    upsertAddress(address: AddressSelector): Observable<AddressSelector>{
        return this.http.post<AddressSelector>(`api/account/upsertAddress`,address);
    }
}
