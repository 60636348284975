import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {ContactStripeComponent} from "./contact-stripe.component";
import {CustomPipesModule} from "../pipe/custom-pipes.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CustomPipesModule
    ],
    declarations: [ContactStripeComponent],
    exports: [ContactStripeComponent]
})

export class ContactStripeModule {}