import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ImageCacheBustDirective} from "./image-cache-bust.directive";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ImageCacheBustDirective
    ],
    exports: [
        ImageCacheBustDirective
    ]
})

export class ImageCacheBustModule {}