import {Component} from "@angular/core";
import {Culture, IDomainInfo, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {HttpClient} from "@angular/common/http";
import {IHeaderContactConfig, IDealer} from "./common";

@Component({
    templateUrl: '../../tpl/header-contact.html',
    selector: 'cmp-index-header'
})

export class IndexHeaderContactComponent extends Translatable {

    headerSettings: IHeaderContactConfig;
    dealerInfo: IDealer;
    cultureOptions: Culture[];
    selectedCulture: Culture;

    constructor(public dataSvc: DataService, public seSvc: SettingsService, private http: HttpClient) {
        super(dataSvc, seSvc);

        this.cultureOptions = seSvc.settings.cultures;
        this.selectedCulture = this.seSvc.culture;
    }

    ngOnInit() {
        var url = 'api/settings/getHeader';
        this.http.get(url)
            .subscribe((res: IHeaderContactConfig) => {
               this.headerSettings = res;
            });

        if (this.isUserB2B) {
            this.http.get('api/user/dealer/')
                .subscribe((res: IDealer) => {
                    this.dealerInfo = res;
                });
        }
    }

    get isShopB2B(): boolean {
        return this.seSvc.domainInfo.b2b;
    }

    get domainInfo(): IDomainInfo {
        return this.seSvc.domainInfo;
    }

    navigate(domain: string): void {
        window.location.href = '//' + domain;
    }

    setCulture(id: number): void {
        this.selectedCulture = this.cultureOptions.filter(c => c.cultureId == id)[0];
        this.seSvc.setCultureAndReload(true, id);
    }

}