
/**
 * Created by jan.hroch on 27.06.2017.
 */

import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {UnpaidInvoicesComponent} from "./unpaid-invoices.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [UnpaidInvoicesComponent],
    exports: [UnpaidInvoicesComponent]
})

export class UnpaidInvoicesModule {}