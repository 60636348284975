/**
 * Feature module for displaying / editing / inserting addresses.
 */
import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddressComponent} from "./address.component";
import {ValidationModule} from "../validation/validation.module";
import {CompanyComponent} from "./company.component";
import {ContactComponent} from "./contact.component";
import {CredentialsComponent} from "./credentials.component";
import {ViesModule} from "../vies-module/vies.module";
import {AddressInvoiceComponent} from "./address-invoice.component";
import {AddressService} from "./address.service";


@NgModule ({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule,
        ViesModule,
    ],
    declarations: [
        AddressComponent,
        AddressInvoiceComponent,
        CompanyComponent,
        ContactComponent,
        CredentialsComponent
    ],
    exports: [
        AddressComponent,
        AddressInvoiceComponent,
        CompanyComponent,
        ContactComponent,
        CredentialsComponent,
    ],
    providers: [
        AddressService
    ]
})

export class AddressModule {}
