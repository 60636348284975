import {Component, Input} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {ViesService} from "./vies.service";
import {ViesRequest, ViesSelector} from "./common";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {finalize, takeUntil} from "rxjs/operators";

@Component({
    selector: 'cmp-vies',
    templateUrl: '../../tpl/vies.html'
})

export class ViesComponent extends Translatable {

    viesInfo: ViesSelector;
    @Input() cssClassPrefix: string = 'user-data';
    countryCodeOptions: string[] = ['CZ', 'SK'];
    viesForm: FormGroup;
    dataLoading: boolean = false;
    errorMessage: string;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(dataSvc: DataService, seSvc: SettingsService, private viesSvc: ViesService,
                private fb: FormBuilder) {
        super(dataSvc, seSvc);
        this.createForm();
    }

    checkVatNumber(): void {
        this.getViesInfo();
    }

    private getViesInfo(): void {

        this.errorMessage = null;
        this.viesInfo = null;
        this.dataLoading = true;

        this.viesSvc.getViesInfo(this.getFormValues())
            .pipe(
                finalize(() => {this.dataLoading = false;}),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((res: ViesSelector) => {
                if (res) {
                    this.viesInfo = res;
                } else {
                    this.errorMessage = this.sen['vies-server-error']
                }
            });
    }

    private createForm(): void {
        this.viesForm = this.fb.group({
            countryCode: [this.countryCodeOptions[0]],
            vatNumber: ['', [Validators.required, Validators.pattern(/\d{8}/), Validators.maxLength(8)]]
        });
    }

    private getFormValues(): ViesRequest {
        return {
            countryCode: this.viesForm.get('countryCode').value,
            vatNumber: this.viesForm.get('vatNumber').value
        }
    }

}