﻿import {Injectable} from '@angular/core';
import {SettingsService} from "./settings.service";

declare let _smartsupp: any;
declare let window: any;

@Injectable()
export class SmartsuppService {
    constructor(private seSvc: SettingsService) {
    }

    public init(): void {
        if (this.seSvc.settings.smartsupp) {
            let apiKey = this.seSvc.settings.smartsupp[this.seSvc.culture.cultureId];
            if (apiKey) {
                window['_smartsupp'] = <any>window['_smartsupp'] || {};
                _smartsupp.key = apiKey;
                window['smartsupp']||(function(d) {
                    let s,c,o: any = window['smartsupp']=function(){ o._.push(arguments)};o._=[];
                    s=d.getElementsByTagName('script')[0];
                    c=d.createElement('script');
                    c.type='text/javascript';c.charset='utf-8';c.async=true;
                    c.src='https://www.smartsuppchat.com/loader.js?';
                    s.parentNode.insertBefore(c,s);
                })(document);
            }
        }
    }
}