import {Injectable} from "@angular/core";
import {ViesRequest} from "./common";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ViesService {

    constructor(private http: HttpClient) {}

    getViesInfo(request: ViesRequest): any {
        return this.http.post(`api/vies/checkVatNumber`, request)
    }

}