import {Pipe, PipeTransform} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";

@Pipe({name: 'stock'})
export class StockAvailability extends Translatable implements PipeTransform {

    constructor(dataSvc: DataService, seSvc: SettingsService) {
        super(dataSvc, seSvc);
    }

    transform(value: number, QuantityOnStockTotal: number = 0): string {

        if((value === 0 && QuantityOnStockTotal === 0 )|| (value === null && QuantityOnStockTotal === 0 )) {
            return this.sen['stock-status-not-available'];
        }
        if((value === 0 && QuantityOnStockTotal > 0 ) || (value === null && QuantityOnStockTotal > 0 )){
            return this.sen['stock-status-somewhere-else'];
        }
        else if(value > 0) {
            return this.sen['stock-status']
        }
        else
            return ""
    }



}