/**
 * @description
 * Component providing validation of Company Registration Number (IČ)
 * NOTE:
 * For best result use VIES API
 * https://github.com/amusarra/VIESCheckVatService
 */
import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators} from "@angular/forms";

/**
 * @description
 * Validation of Czech DIČ - not too exact, but due to documentation there are many exceptions to 'CZ' + IČ
 * @param x
 * @returns {boolean}
 */
export function testVatCz(x: string): boolean {
    if (x) {
        let lengthTest = x.length > 7 && x.length <= 12;
        let patTest = /^CZ\d{8,10}$/.test(x);
        return lengthTest && patTest;
    } else return false;
}

export function vatValidator(culture: string): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {
        if (!control || !control.value) return null;
        const vat: string = control.value ? control.value.replace(/\s+/g, ''): null;
        let no: boolean;
        switch (culture) {
            case 'cs-CZ':
                no = !testVatCz(vat);
                return no ? {'vatNumber': {vat}} : null;
            default:
                return null;
        }
    };
}

@Directive({
    selector: '[vatNumber]',
    providers: [{provide: NG_VALIDATORS, useExisting: VatValidatorDirective, multi: true}]
})

export class VatValidatorDirective implements Validator, OnChanges {

    @Input('vatNumber') vatNumber: string;
    private valFn = Validators.nullValidator;

    ngOnChanges(changes: SimpleChanges): void {
        const change = changes['vatNumber'];
        if (change) {
            let val = change.currentValue;
            this.valFn = vatValidator(val);
        } else {
            this.valFn = Validators.nullValidator;
        }
    }

    validate(control: AbstractControl): { [key: string]: any } {
        return this.valFn(control);
    }
}