import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductAmountComponent} from "./product-amount.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ValidationModule} from "../validation/validation.module";
import {CartItemPipe, ProductAmountPipe, VariantAmountPipe} from "./product-amount.pipe";


@NgModule({
    declarations: [
        ProductAmountComponent,
        ProductAmountPipe,
        VariantAmountPipe,
        CartItemPipe
      /*  ProductAmountCartPipe,
        ProductVariantAmountPipe,
        ProductBoxModelAmountPipe*/
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule
    ],
    exports: [
        ProductAmountComponent,
        ProductAmountPipe,
        VariantAmountPipe,
        CartItemPipe
   /*     ProductAmountCartPipe,
        ProductVariantAmountPipe,
        ProductBoxModelAmountPipe*/
    ]
})
export class ProductAmountModule {
}
