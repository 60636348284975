/**
 * Module containing all features related to login:
 * - LoginComponent with the form for entering user credentials
 * - SignOutComponent - for signing user out
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from "./login.component";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ValidationModule} from "../validation/validation.module";


@NgModule({
    imports: [
        CommonModule, 
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ValidationModule
    ],
    declarations: [
        LoginComponent
    ],
    exports: [LoginComponent]
})

export class LoginModule {}
