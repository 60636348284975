import {Component, Input} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";

declare let sha256: any;

@Component({
    selector: 'cmp-credentials',
    templateUrl: '../../tpl/credentials.html'
})

export class CredentialsComponent extends Translatable {

    @Input() cssClassPrefix: string = 'user-data';
    credForm: FormGroup;

    constructor(dataSvc: DataService, seSvc: SettingsService, private fb: FormBuilder) {
        super(dataSvc, seSvc);

        this.createForm();
    }

    private createForm(): void {
        this.credForm = this.fb.group({
            password: ['', [Validators.required, Validators.minLength(6), Validators.pattern(/\d/)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(6), Validators.pattern(/\d/)]]
        })
    }

    getPassword(): string {
        return sha256(this.credForm.get('password').value);
    }

    forceDirty(): void {
        for (let obj in this.credForm.controls) {
            (this.credForm.controls[obj] as AbstractControl).markAsDirty();
        }
    }

}