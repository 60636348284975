import {Directive, ElementRef, Input, OnInit, Renderer2} from "@angular/core";
import {SettingsService} from "../../services/settings.service";
import {getRelativeUrlParts, getUrlParts} from "../../helpers/string.helper";

@Directive({
    selector: 'use'
})

export class SvgCacheBustDirective implements OnInit {


    private _href: string;
    @Input() set href(value: string) {
        const url = (value || '').indexOf('://') > -1 ? getUrlParts(value) : getRelativeUrlParts(value);
        url.setParams({...url.getParams(), cbk: this.seSvc.cacheBustKey});
        this._href = url.toString();
        this.renderer.setAttribute(this.elRef.nativeElement, 'href', this._href);
    }

    get href(): string {
        return this._href;
    }

    constructor(private elRef: ElementRef, private renderer: Renderer2, private seSvc: SettingsService) {}

    ngOnInit(): void {

    }
}