import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AddressEditModalComponent} from "./address-edit-modal.component";
import {AddressModule} from "../address/address.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AddressModule
    ],
    declarations: [
        AddressEditModalComponent
    ],
    exports: [
        AddressEditModalComponent
    ]
})

export class AddressEditModalModule {}