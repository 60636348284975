import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {ArticleListSectionComponent} from "./article-list-section.component";
import {ArticleService} from "./article.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule
    ],
    declarations: [
        ArticleListSectionComponent
    ],
    exports: [
        ArticleListSectionComponent
    ],
    providers:[
        ArticleService
    ]
})

export class ArticleListSectionModule {
}