import {EventEmitter, Injectable} from "@angular/core";
import {loadFromLocalStorage, saveToLocalStorage} from "../helpers/cookie.helper";

@Injectable()
export class CompareProductsService {

    private storageKey = '--compareProducts--';
    private maxLength: number = 9;

    public productsChanged: EventEmitter<number[]>;

    constructor() {
        this.productsChanged = new EventEmitter<number[]>();
    }

    public addProduct(productId: number): boolean {
        if (!productId) {
        }
        let prodIds: number[] = this.getProductIds();
        if (prodIds.length == this.maxLength) {
            return false;
        }

        let index = prodIds.indexOf(productId);
        if (index > -1) {
            return null;
        }

        prodIds.push(productId);

        saveToLocalStorage(this.storageKey, prodIds);
        this.productsChanged.emit(prodIds);
        return true;
    }

    public removeProduct(productId: number): boolean {
        if (!productId) {
        }
        let prodIds: number[] = this.getProductIds();

        if (!prodIds) {
            return null;
        }

        let index = prodIds.indexOf(productId);
        if (index == -1) {
            return false;
        }

        prodIds = prodIds.filter(id => id != productId);

        saveToLocalStorage(this.storageKey, prodIds);
        this.productsChanged.emit(prodIds);
        return true;
    }

    public getProductIds(): number[] {
        let prodIds = loadFromLocalStorage(this.storageKey);
        if (!prodIds) {
            prodIds = [];
        }
        prodIds = this.sanitize(prodIds);
        return prodIds;
    }

    public get count(): number {
        return this.getProductIds().length;
    }

    public contains(productId: number): boolean {
        if (!productId) {
            return false;
        }
        return this.getProductIds().indexOf(productId) > -1;
    }

    private sanitize(ids: any[]): number[] {
        ids = ids.filter(val => !isNaN(parseFloat(val)) && isFinite(val));
        saveToLocalStorage(this.storageKey, ids);
        return ids;
    }
}