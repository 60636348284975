import {Component} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {NavigationEnd, Router} from "@angular/router";
import {Subject} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {SettingItem} from "./common";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'cmp-notify-stripe',
    templateUrl: '../../tpl/notify-stripe.html'
})

export class NotifyStripeComponent extends Translatable {

    info: SettingItem;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, public seSvc: SettingsService,
                private router: Router, private sanitizer: DomSanitizer, private http: HttpClient) {
        super(dataSvc, seSvc);

        this.router.events.subscribe(res => {
            if (res instanceof NavigationEnd) {
                this.getData();
            }
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getData(): void {
        this.http.get<SettingItem[]>('api/settings/getNotifyStripe')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
                if (res && res.length > 0) {
                    this.info = res[0];
                    if (this.info && this.info.value) {
                        this.info.value = this.sanitizer.bypassSecurityTrustHtml(<string>this.info.value);
                    } else {
                        this.info.value = null;
                    }
                }
                else{
                    this.info.value = null;
                }
            });
    }

}
