/**
 * Little shopping cart view available on every page.
 */
import {Component, ViewEncapsulation} from '@angular/core';
import {CartService} from "../cart/cart.service";
import {Culture, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'cmp-cart-indicator',
    templateUrl: '../../tpl/cart-indicator.html',
    styleUrls: ['../../../assets/styles/3-layout/header/cart.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CartIndicatorComponent extends Translatable {

    culture: Culture;
    imagePathPrefix: string;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, public seSvc: SettingsService,
                public cartSvc: CartService) {
        super(dataSvc, seSvc);

        this.culture = this.seSvc.culture;
        this.imagePathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/suggest/images/';

        cartSvc.cartContentChanged
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                //this.getCartContent()
            });

        this.cartSvc.cartEmptied
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {

            })
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}