import {NgModule} from '@angular/core';
import {CropHtml} from "./cropHtml";
import {StockAvailability} from "./stockAvailability";
import {DecimalPipe} from "@angular/common";
import {
    DomSanitizerSafeHtmlPipe,
    DomSanitizerSafeResourceUrlPipe,
    DomSanitizerSafeStylePipe,
    DomSanitizerSafeUrlPipe
} from "./dom-sanitizer.pipes";
import {UnitsPipe} from "./units.pipe";
import {WebpPipe} from "./webp.pipe";

@NgModule({
    declarations: [
        CropHtml,
        StockAvailability,
        DomSanitizerSafeStylePipe,
        DomSanitizerSafeHtmlPipe,
        DomSanitizerSafeUrlPipe,
        DomSanitizerSafeResourceUrlPipe,
        UnitsPipe,
        WebpPipe
    ],
    exports: [
        CropHtml,
        StockAvailability,
        DomSanitizerSafeStylePipe,
        DomSanitizerSafeHtmlPipe,
        DomSanitizerSafeUrlPipe,
        DomSanitizerSafeResourceUrlPipe,
        UnitsPipe,
        WebpPipe
    ],
    providers: [
        DecimalPipe
    ]
})

export class CustomPipesModule {
}
