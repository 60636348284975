import {Pipe, PipeTransform} from "@angular/core";
import {IProductAmountSettings} from "./interfaces/IProductAmountSettings";
import {IProduct} from "../product/common";
import {IProductList} from "../../interfaces/general";
import {
    CartItemAmountAdapter,
    ProductAmountAdapter,
    ProductVariantAmountAdapter
} from "../../helpers/ProductAmountAdapter";
import {CartItemSelectorFull} from "../cart/common";

@Pipe({name: 'ProductAmount'})
export class ProductAmountPipe implements PipeTransform {

    transform(product: IProduct): IProductAmountSettings {
        if (!product)
            return null;

        if((<any>product).id) {
            return new ProductVariantAmountAdapter(<IProductList><any>product);
        } else {
            return new ProductAmountAdapter(<IProduct>product);
        }
    }

}

@Pipe({name: 'VariantAmount'})
export class VariantAmountPipe implements PipeTransform {

    transform(product: IProductList): IProductAmountSettings {
        if (!product)
            return null;

        return new ProductVariantAmountAdapter(<IProductList><any>product);
    }

}

@Pipe({name: 'CartItemAmount'})
export class CartItemPipe implements PipeTransform {
    transform(product: CartItemSelectorFull): IProductAmountSettings {
        if (!product)
            return null;

        return new CartItemAmountAdapter(<CartItemSelectorFull><any>product);
    }
 }
