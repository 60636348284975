import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserSelector} from "./common";
import {UserExistsRequestModel, UserExistsResultModel} from "../register-b2c/common";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'cmp-contact',
    templateUrl: '../../tpl/contact.html'
})

export class ContactComponent extends Translatable implements OnChanges {

    @Input() user: UserSelector;
    @Input() cssClassPrefix: string = 'user-data';
    @Input() loginReadOnly: boolean = false;
    @Input() readonly: boolean = false;
    @Input() checkForExistingUser: boolean = true;
    @Output() userExists: EventEmitter<boolean>;
    @Output() changes: EventEmitter<UserSelector> = new EventEmitter<UserSelector>();

    contactForm: FormGroup;
    mailPat: RegExp;
    phonePat: RegExp;
    userExistsError: boolean = false;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(dataSvc: DataService, seSvc: SettingsService, private fb: FormBuilder, private http: HttpClient) {
        super(dataSvc, seSvc);
        this.mailPat = this.seSvc.settings.validationPatterns.email;
        this.phonePat = this.seSvc.settings.validationPatterns.phone[this.seSvc.culture.cultureId];

        this.userExists = new EventEmitter();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['user']) {
            this.user = changes['user'].currentValue;
            this.createForm();
        }
    }

    getContact(): UserSelector {
        return {
            id: this.contactForm.get('id').value,
            companyId: this.contactForm.get('companyId').value,
            email: this.contactForm.get('email').value,
            phone: this.contactForm.get('phone').value,
            firstName: this.contactForm.get('firstName').value,
            lastName: this.contactForm.get('lastName').value
        }
    }

    forceDirty(): void {
        for (let obj in this.contactForm.controls) {
            (this.contactForm.controls[obj] as AbstractControl).markAsDirty();
        }
    }

    validateUser(userName: string): void {
        if (!this.checkForExistingUser) return;

        let request: UserExistsRequestModel = {userName: userName};
        this.http.post('api/user/exists', request)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: UserExistsResultModel) => {
                this.userExistsError = res.exists;
                this.userExists.emit(res.exists)
            })
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private createForm(): void {

        if (!this.user) {
            this.user = {
                id: 0,
                companyId: 0,
                email: '',
                phone: '',
                firstName: '',
                lastName: ''
            }
        }

        this.contactForm = this.fb.group({
            id: [this.user.id],
            companyId: [this.user.companyId],
            email: [{
                value: this.user.email,
                disabled: this.loginReadOnly
            }, [Validators.required, Validators.pattern(this.mailPat)]],
            phone: [this.user.phone, [Validators.required, Validators.pattern(this.phonePat)]],
            firstName: [this.user.firstName, [Validators.required]],
            lastName: [this.user.lastName, [Validators.required]]
        });
        this.contactForm.valueChanges.subscribe(() => {
            this.changes.emit(this.getContact());
        });
    }

}