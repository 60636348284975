import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {CartChangeModalComponent} from "./cart-change-modal.component";
import {FreeDeliveryProgressBarModule} from "../free-delivery-progress-bar/free-delivery-progress-bar.module";
import {ValidationModule} from "../validation/validation.module";
import {ProductAmountModule} from "../product-amount/product-amount.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FreeDeliveryProgressBarModule,
        ValidationModule,
        ProductAmountModule
    ],
    declarations: [CartChangeModalComponent],
    exports: [CartChangeModalComponent]
})

export class CartChangeModalModule {}