import {Component, ElementRef, ViewEncapsulation} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {IBenefit} from "../benefit-list/common";
import { takeUntil, finalize } from 'rxjs/operators';
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ErrorService} from "../../services/error.service";
import {Router} from "@angular/router";

declare let $: any;

@Component({
    selector: 'cmp-contact-stripe',
    templateUrl: '../../tpl/contact-stripe.html',
    styleUrls: ['../../../assets/styles/3-layout/contact-stripe.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ContactStripeComponent extends Translatable {

    benefits: IBenefit[];
    benefitFotocachePrefix: string;
    baseHref: string;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, public seSvc: SettingsService, private router: Router,
                private http: HttpClient, private errSvc: ErrorService, private eleRef: ElementRef) {
        super(dataSvc, seSvc);

        this.benefitFotocachePrefix = seSvc.settings.imageServerPathPrefix + '/fotocache/benefit/images/';
        this.linkHandler();
    }

    ngOnInit(): void {
        this.getBenefits();
    }

    getBenefits(): void {
        this.dataSvc.dataLoading = true;
        this.http.get<IBenefit[]>(`api/benefit/get/${this.seSvc.culture.cultureId}`)
            .pipe(
                takeUntil(this.ngUnsubscribe),
                finalize(() => {
                    this.dataSvc.dataLoading = false;
                })
            )
            .subscribe((res) => {
                if (res)
                    this.benefits = res;
            },
            (err) => {
                this.errSvc.handleError(err)
            });
    }

    linkHandler(): void {
        this.baseHref = $('base')[0].href;
        $(this.eleRef.nativeElement).on('click', 'a', (e: any) => {
            if (e.target.href.startsWith(this.baseHref) && e.target.target !== '_blank') {
                e.preventDefault();
                let link = ('/' + e.target.href.replace(this.baseHref, '')).replace('//', '/');
                this.router.navigateByUrl(link).then(() => {});
            }
        })
    }
}