/**
 * @description
 * Component providing validation of Company Registration Number (IČ)
 * Note:
 * For best result use ARES API
 */
import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators} from "@angular/forms";

/**
 * Validation of Czech IČ
 * @param x
 * @returns {boolean}
 */
export function testCrnCz(x: string) {
    try {
        let a = 0;
        if (x.length == 0) return true;
        if (x.length != 8) return false;
        let b = x.split('');
        let c;
        for (let i = 0; i < 7; i++) a += (parseInt(b[i]) * (8 - i));
        a = a % 11;
        c = 11 - a;
        if (a == 1) c = 0;
        if (a == 0) c = 1;
        if (a == 10) c = 1;
        if (parseInt(b[7]) != c) return false;
    }
    catch (e) {
        return false;
    }
    return true;
}

export function crnValidator(culture: string): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {
        const crn = control.value ? control.value.replace(/\s+/g, ''): null;
        let no: boolean;
        switch (culture) {
            case 'cs-CZ':
                no = !testCrnCz(crn);
                return no ? {'crnNumber': {crn}} : null;
            default:
                return null;
        }
    };
}

@Directive({
    selector: '[crnNumber]',
    providers: [{provide: NG_VALIDATORS, useExisting: CrnValidatorDirective, multi: true}]
})

export class CrnValidatorDirective implements Validator, OnChanges {

    @Input('crnNumber') crnNumber: string;
    private valFn = Validators.nullValidator;

    ngOnChanges(changes: SimpleChanges): void {
        const change = changes['crnNumber'];
        if (change) {
            let val = change.currentValue;
            this.valFn = crnValidator(val);
        } else {
            this.valFn = Validators.nullValidator;
        }
    }

    validate(control: AbstractControl): { [key: string]: any } {
        return this.valFn(control);
    }
}