import {InjectFlags, InjectionToken, Injector, Type} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {Sentences} from "../../services/settings.service";

export class GeneralDialogInjector implements Injector {
    constructor(
        private _parentInjector: Injector,
        private _additionalTokens: WeakMap<any, any>
    ) {}

    get<T>(
        token: Type<T> | InjectionToken<T>,
        notFoundValue?: T,
        flags?: InjectFlags
    ): T;
    get(token: any, notFoundValue?: any):any;
    get(token: any, notFoundValue?: any, flags?: any) {
        if(flags){}//to prevent "'flags' is declared but its value is never read" error
        
        const value = this._additionalTokens.get(token);
        const parent = this._parentInjector.get<any>(token, notFoundValue);

        if (value) return value;

        return parent;
    }
}

export class GeneralDialogRef {

    constructor() {}

    close(result?: any) {
        this._afterClosed.next(result);
    }

    private readonly _afterClosed = new Subject<any>();
    afterClosed: Observable<any> = this._afterClosed.asObservable();
}

export interface IMessageBoxConfig<T> {
    message: string;
    buttons: IMessageBoxButton<T>[];
}

export interface IMessageBoxButton<T> {
    value: T;
    text: string;
    settings: IMessageBoxButtonSettings;
}

export interface IMessageBoxButtonSettings {
    Class: string;
}

export class MessageBoxButtons {

    /**
     * Tlačítko s návratovou hodnotou 'Yes'. Zobrazený text je sen['yes']
     * @param settings - nastaveni classy pro změnu zobrazení tlačítka
     * @constructor
     */
    public static Yes(settings: IMessageBoxButtonSettings = null): IMessageBoxButton<string> {
        return <IMessageBoxButton<string>>{
            value: 'Yes',
            text: Sentences.sen['app-yes'],
            settings: settings
        };
    }

    /**
     * Tlačítko s návratovou hodnotou 'No'. Zobrazený text je sen['no']
     * @param settings - nastaveni classy pro změnu zobrazení tlačítka
     * @constructor
     */
    public static No(settings: IMessageBoxButtonSettings = null): IMessageBoxButton<string> {
        return <IMessageBoxButton<string>>{
            value: 'No',
            text: Sentences.sen['app-no'],
            settings: settings
        };
    }

    /**
     * Tlačítko s návratovou hodnotou 'OK'. Zobrazený text je sen['ok']
     * @param settings - nastaveni classy pro změnu zobrazení tlačítka
     * @constructor
     */
    public static OK(settings: IMessageBoxButtonSettings = null): IMessageBoxButton<string> {
        return <IMessageBoxButton<string>>{
            value: 'OK',
            text: Sentences.sen['app-ok'],
            settings: settings
        };
    }

    /**
     * Tlačítko s návratovou hodnotou 'Close'. Zobrazený text je sen['close']
     * @param settings - nastaveni classy pro změnu zobrazení tlačítka
     * @constructor
     */
    public static Close(settings: IMessageBoxButtonSettings = null): IMessageBoxButton<string> {
        return <IMessageBoxButton<string>>{
            value: 'Close',
            text: Sentences.sen['app-close'],
            settings: settings
        };
    }
}