// Digest realm="FlexRealm", nonce="db34cb20d445e9639e1f36f55aa95376", qop="auth"

import {IUrl} from "../interfaces/general";
import {Params} from "@angular/router";

export class KeyValuePair {

    key: string;
    value: string;

    constructor (k: string, v: string) {
        this.key = k;
        this.value = v;
    }
}

export function parseMultiPartString(digest: string): Array<KeyValuePair> {

    digest = digest.replace(/Digest /i, '').replace(/\s*/g, '');

    let kvpArray = digest.split(',');
    let result: Array<KeyValuePair> = [];

    function fillResult(v:string, a:Array<KeyValuePair>) {
        let tmp = v.split('=');
        a.push(new KeyValuePair(tmp[0], tmp[1].replace(/"/g, '')))
    }

    kvpArray.forEach(v => fillResult(v, result));

    return result;
}

export function isRouteSecured(theRoute: string): boolean {
    return /^\/muj-ucet.*/.test(theRoute);
}

export function getUrlParts(url: string): Url {
    const reg = /^(?:(.+)?\:\/\/(.+?))?(\/.*?)?(?:\?(.*?))?(?:\#(.*?))?$/;
    let m = reg.exec(url);
    return m ? new Url(m) : null;
}

export function getRelativeUrlParts(url: string): Url {
    const reg = /^(.+?)(?:\?(.+?))?(?:#(.+))?$/;
    let m = reg.exec(url);
    if (!m)
        return null;
    let res = new Url();
    res.path = m[1] || '';
    res.query = m[2] || '';
    res.hash = m[3] || '';
    return res;
}

export class Url implements IUrl {
    public schema: string = '';
    public domain: string = '';
    public path: string = '';
    public query: string = '';
    public hash: string = '';

    constructor(exec?: RegExpExecArray) {
        if (exec) {
            this.schema = exec[1] || '';
            this.domain = exec[2] || '';
            this.path = exec[3] || '';
            this.query = exec[4] || '';
            this.hash = exec[5] || '';
        }
    }

    public toString(): string {
        const queryString = this.query ? `?${this.query}` : '';
        const hash = this.hash ? `#${this.hash}` : '';
        const domain = this.domain ? `${this.schema}://${this.domain}` : '';
        return `${domain}${this.path}${queryString}${hash}`;
    }

    public setParams(params: Params): void {
        this.query = ParamsToQueryString(params);
    }

    public getParams(): Params {
        return QueryStringToParams(this.query);
    }

}

export function QueryStringToParams(query: string): Params {
    let result: Params = {};

    for (let q of query.split('&')) {
        const split = q.split('=');

        let key = split[0];
        let value = split[1];

        //queryParams v routingu zvladne zduplikovat pole pro jeden klic, tedy např. [queryParams]="{fl:['1','2']}" se v url prevede na ?fl=1&fl=2
        //prave kvuli moznemu duplicitnimu klici v url je treba vice hodnot schovat do pole pod jeden klic v Params, jinak se vzdy ulozila jen ta posledni
        let paramValueArray: Array<string> = result[key];
        if (paramValueArray) {
            paramValueArray.push(value);

        } else {
            result[key] = new Array(value);
        }
    }

    return result;
}

export function ParamsToQueryString(params: Params): string {
    return Object.entries(params)
        .filter(([_, value]) => value !== null && value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
}