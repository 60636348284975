import {Component, Inject, Renderer2, ViewEncapsulation} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {CookiesConsentService} from "./cookies-consent.service";
import {DOCUMENT} from "@angular/common";


@Component({
    selector: 'cmp-js-scripts',
    templateUrl: '../../tpl/js-scripts.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None
})

export class JsScriptsComponent extends Translatable  {
    constructor(public dataSvc: DataService, public seSvc: SettingsService,
                public ccSvc: CookiesConsentService,
                private renderer:Renderer2,
                @Inject(DOCUMENT) private document: Document) {
        super(dataSvc, seSvc);
    }

    ngOnInit() {
        this.addGTM();
        this.addGA();
    }


    public addGA() {
        if(!(this.ccSvc.isGoogleAnalytics() || this.ccSvc.isGoogleAdsRemarketing()))
        {
            return;
        }

        let script = this.renderer.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${this.seSvc.gaSettings.analyticsId}`;
        script.async = true;
        this.renderer.appendChild(this.document.body, script);

        let gaConfig = '';
        if(this.ccSvc.isGoogleAnalytics()){
            gaConfig = `gtag('config', '${this.seSvc.gaSettings.analyticsId}');`
        }

        let grmConfig='';
        if(this.ccSvc.isGoogleAdsRemarketing()){
            grmConfig = `gtag('config', '${this.seSvc.gaSettings.remarkId}');`;
        }

        const txt = `window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        ${gaConfig}
        ${grmConfig}
        `;
        this.addScript(txt);
    }


    public addGTM() {
        if(!this.ccSvc.isGtm())
        {
            return;
        }

        const txt = `(function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-${this.seSvc.gaSettings.gtmId}');`;
        this.addScript(txt);
    }

    private addScript(scriptText:string){
        let script = this.renderer.createElement('script');
        //script.type = `application/ld+json`;
        script.text = scriptText;

        this.renderer.appendChild(this.document.body, script);
    }
}