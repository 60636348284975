/**
 * @description
 * Provides functionality for Home Page
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PopupB2bComponent} from "./popup-b2b.component";

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        PopupB2bComponent
    ],
    exports: [
        PopupB2bComponent
    ],
    entryComponents:[
        PopupB2bComponent
    ]
})

export class PopupB2bModule {
}