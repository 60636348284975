import {Component, ViewEncapsulation} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {CartService} from "../cart/cart.service";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {CartItemSelectorBrief, CartItemSelectorFull} from "../cart/common";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {IProductAmountConfirm} from "../product-amount/interfaces/IProductAmountConfirm";

declare let $: any;

@Component({
    selector: 'cmp-cart-change-modal',
    templateUrl: '../../tpl/cart-change-modal.html',
    styleUrls: ['../../../assets/styles/2-components/buy-window.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CartChangeModalComponent extends Translatable {

    cartItem: CartItemSelectorFull;
    productImagePathPrefix: string;
    timeoutId: any;
    timeOutDelay: number = 3500000;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, public seSvc: SettingsService,
                private cartSvc: CartService) {
        super(dataSvc, seSvc);

        this.productImagePathPrefix = seSvc.settings.imageServerPathPrefix + '/fotocache/basket/images/';

        this.cartSvc.cartItemCountChanged
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: CartItemSelectorBrief) => {

                if (res && !res.fromCart && !res.disableMessage) {

                    if (!this.cartItem){
                        this.cartItem = this.cartSvc.productList.find(p => p.id == res.id);
                        //this.seSvc.modalOpen = true;

                        if (this.timeOutDelay > 0) {
                            if (this.timeoutId) clearTimeout(this.timeoutId);
                            this.timeoutId = setTimeout(() => {
                                this.closeCartAddDialog();
                            }, this.timeOutDelay);
                        }
                    }

                }
            });
    }

    closeCartAddDialog(): void {
        //this.seSvc.modalOpen = false;
        this.cartItem = null;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    onCountChanged(cartItem: CartItemSelectorFull, value: number){
        this.changeCount(cartItem, value.toString());
    }
    onConfirm(cartItem: CartItemSelectorFull, value: IProductAmountConfirm){
        this.changeCount(cartItem, value.Amount.toString());
    }
    //region Amount change

    changeCount(cartItem: CartItemSelectorFull, newCount: string, delta?: number): void {


        if ($.isNumeric(newCount)) {
            let newCountNumber = parseFloat(newCount.toString());
            // toto nemuze fungovat pro desetine cisla
            /*if (!delta && !this.isValidCount(newCountNumber, cartItem.productDetail.MinQtyOrder)) {
                return;
            }*/

            if (delta && delta + newCountNumber >= 0) {
                newCountNumber = Math.max((cartItem.productDetail.MinQtyOrder || 1), newCountNumber + delta);
                newCountNumber = Math.floor(newCountNumber / (cartItem.productDetail.MinQtyOrder || 1)) * (cartItem.productDetail.MinQtyOrder || 1)
            }
            if (newCountNumber >= cartItem.productDetail.MinQtyOrder) {
                let cart: CartItemSelectorBrief = {
                    count: newCountNumber,
                    id: cartItem.id,
                    productId: cartItem.productId
                };
                this.cartSvc.changeCount(cart);
            }
        }
    }

    isValidCount(qty: any, minQtyOrder: number): boolean {
        qty = +qty; //fast parse
        return Math.floor(qty / minQtyOrder) * minQtyOrder === qty;
    }
    //endregion
}