export class MathUtils {

    private static mult: number = 10000000;

    public static IsMultiple(left: number | string, right: number): boolean {
        if(typeof left === 'string') {
            left = parseFloat((<string>left).replace(',', '.'));
        }

        if (right) {
            const a = left / right;
            const b = Math.round(a) * right;
            const c = Math.round(b * this.mult) === Math.round(left * this.mult);
            return c;
        } else {
            return true;
        }
    }

    public static Round(value: number, decimalPlaces: number) {
        const tmp = Math.pow(10, decimalPlaces);
        return Math.round(value * tmp) / tmp;
    }

}