import {EventEmitter, Injectable} from "@angular/core";
import {isRouteSecured, parseMultiPartString} from '../helpers/string.helper';
import {AuthInfo, CartAdjustAction, LoginEmitterMessage, LoginResult} from "../interfaces/general";
import {SettingsService} from "./settings.service";
import {SharedAppSettings} from "../shared-settings/shared-settings";
import {ICartStatus} from "../modules/cart/common";
import {Router} from "@angular/router";
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from "@angular/common/http";
import {CredentialStorage} from "./credential-storage.service";
import {CartService} from "../modules/cart/cart.service";
import {loadFromSession, removeFromSession, saveToSession} from "../helpers/cookie.helper";

declare let sha256: any;
declare let moment: any;
declare let $: any;

// todo: UNIT TEST FOR THIS SERVICE

@Injectable()
export class DigestService {

    nonce: string;
    realm: string;
    cnonce: string;
    userName: string;
    password: string;
    requestedUri: string;
    requestedMethod: string;
    cookieExpHours: number;

    loginStatus: EventEmitter<LoginEmitterMessage>;

    constructor(private http: HttpClient, private seSvc: SettingsService,
                private sapSvc: SharedAppSettings, private router: Router, private storage: CredentialStorage, private  cartSvc: CartService) {

        const perma = this.storage.permanent;
        let aiValid: boolean = false;

        const ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            const mt = moment();
            const mtValidTo = moment(ai.validTo);
            if (mtValidTo > mt) {
                aiValid = true;
            }
        }

        if (!aiValid && !perma) {
            /**
             * This means fresh start, nothing in session/local, we settle new values
             */
            this.initNotSigned();
        } else {
            /**
             * this means there was full page reload, so we rather take stored values
             * */
            this.cnonce = ai.clientNonce;
            this.userName = ai.userName;
        }

        this.cookieExpHours = perma ? this.sapSvc.Defaults.PermanentLoginExpirationHours : null;

        this.loginStatus = new EventEmitter();
    }

    private initNotSigned(): void {
        CredentialStorage.removeAuthInfo();
        this.cnonce = sha256((Math.random() * 1000000).toString());
    }

    // BLOCK ADJUST CART


    private getCartStatus(): any {
        let url = `api/cart/getCartStatus`;
        return this.http.get(url)
    }

    showAdjustDialog(): void {
        this.seSvc.loginOpen = false;
        this.seSvc.modalOpen = true;
        $('.modal-adjust-cart').removeClass('display-none');
    }

    closeAdjustDialog(): void {
        this.seSvc.modalOpen = false;
        $('.modal-adjust-cart').addClass('display-none');
    }

    setAdjustAction(action: string): void {
        this.adjustCart(<CartAdjustAction>action);
        this.closeAdjustDialog();
    }

    adjustCart(action: CartAdjustAction): void {

        let url = `api/cart/cartAdjust/${action ? action : ''}`;
        this.http.get(url)
            .subscribe(() => {
                // cart adjusted saved to session not to repeat it in the same session
                saveToSession('cadj', true);
                location.reload();
            });

    }

    // BLOCK ADJUST CART

    login(userName: string, pwd: string): void {

        this.cookieExpHours = this.storage.permanent ? this.sapSvc.Defaults.PermanentLoginExpirationHours : null;

        this.requestedUri = this.storage.permanent ? 'api/login?permanent' : 'api/login';
        this.requestedMethod = 'GET';

        this.userName = userName;
        this.password = pwd;

        this.http.get(this.requestedUri)
            .subscribe(
                res => {
                    this.loginStatus.emit({type: 'info', message: this.userName, data: res})
                },
                (err) => {
                    let headers: HttpHeaders = this.getAuthHeaderOnChallenge(err);
                    this.reGetWithAuth(this.requestedUri, headers)
                        .subscribe(
                            (r: LoginResult) => {
                                CredentialStorage.authInfo = {
                                    a: r.a,
                                    b: r.b,
                                    c: r.c,
                                    realm: this.realm,
                                    nonce: this.nonce,
                                    clientNonce: this.cnonce,
                                    userName: this.userName,
                                    displayName: r.companyDisplayName,
                                    hu: r.hu,
                                    isB2B: r.isB2B,
                                    isAdmin: r.isAdmin,
                                    loggedIn: true,
                                    validTo: this.storage.permanent
                                        /**
                                         * README IMPORTANT
                                         * The "-5" offset should cause user kick-off on the client side first
                                         * before the server nonce expires
                                         */
                                        ? moment().add(this.cookieExpHours - 5, 'hours').format()
                                        : moment().add(this.sapSvc.Defaults.NonceTimeMinutes, 'minutes').format(),
                                    companyId: r.companyId
                                };
                                this.seSvc.setCulture(r.cultureId, r.currencyId);

                                /**
                                 * remove this block (BLOCK ADJUST CART) and all concerned functions
                                 * if you don't want to use cart adjustment after login
                                 * place just single location.reload(); in place of this code
                                 */
                                    // BLOCK ADJUST CART

                                const cartAlreadyAdjusted = <boolean>loadFromSession('cadj');
                                if (!cartAlreadyAdjusted) {
                                    this.getCartStatus()
                                        .subscribe((res: ICartStatus) => {
                                            this.cartSvc.cartTokenSvc.saveCartToken(res.cartToken);
                                            if (res.NotLoggedProductsCnt > 0 && res.PrevLoggedProductsCnt > 0 && this.seSvc.settings.cartAdjustAction == 'ask') {
                                                this.showAdjustDialog();
                                            } else {
                                                this.adjustCart(null);
                                            }
                                        });
                                } else {
                                    location.reload();
                                }

                                // BLOCK ADJUST CART
                            },
                            (e: HttpErrorResponse) => this.loginStatus.emit({
                                type: 'error',
                                message: 'bad credentials, login failed',
                                data: e
                            })
                        )
                }
            );
    }

    logOut(routeToIndex?: boolean) {

        CredentialStorage.removeAuthInfo();
        this.storage.permanent = false;
        this.userName = undefined;
        this.password = undefined;
        removeFromSession('cadj');

        this.cartSvc.cleanCartOnLogout();

        let fwd = this.router.url;
        let url;
        if (routeToIndex || isRouteSecured(fwd)) {
            if (routeToIndex) {
                url = '/';
            } else {
                url = fwd ? `/upozorneni?fwd=${fwd}` : '/upozorneni';
            }
            this.router.navigateByUrl(url).then(() => {
                location.reload();
            });
        } else {
            location.reload();
        }
    }

    private reGetWithAuth(uri: string, headers: HttpHeaders): any {
        return this.http.get(uri, {headers: headers});
    }

    private getAuthHeaderOnChallenge(res: HttpResponse<any>): HttpHeaders {

        let authHeader = res.headers.get('www-authenticate');
        let parsed = parseMultiPartString(authHeader);

        this.realm = parsed[0]['value'];
        this.nonce = parsed[1]['value'];

        const x = Math.floor(Math.random() * 100);
        const y = 0;

        /**
         * Compute sha256 hash of HA1:nonce:x:cnonce:qop:HA2.
         * Example, sha256 hash of string aa71f01f351:dcd98b…bfb0c093:00000001:0a4f113b:auth:939e7552ac
         */

        let HA1 = CredentialStorage.ha1(this.userName, this.realm, this.password);
        let HA2 = CredentialStorage.ha2(this.requestedMethod, this.requestedUri);

        let response = sha256(`${HA1}:${this.nonce}:${x}:${y}:${this.cnonce}:auth:${HA2}`);
        /**
         * WARNING: keep this long string on single line, otherwise Chrome will fuck up this operation
         * @type {string}
         */
        let result = `Digest username="${this.userName}", realm="${this.realm}", nonce="${this.nonce}", uri="${this.requestedUri}", qop="auth", x=${x}, cnonce="${this.cnonce}", response="${response}"`;

        return new HttpHeaders({'Authorization': result});
    }
}
