import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Translatable} from '../../interfaces/general';
import {DataService} from '../../services/data.service';
import {SettingsService} from '../../services/settings.service';
import {CartService} from "../cart/cart.service";
import {DeliveryPaymentService} from "../delivery-payment/delivery-payment.service";
import {IDeliveryFreeFrom} from "../cart/common";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";


@Component({
    selector: 'cmp-free-delivery-progress-bar',
    templateUrl: '../../tpl/free-delivery-progress-bar.html',
    styleUrls: ['../../../assets/styles/2-components/free-delivery.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FreeDeliveryProgressBarComponent extends Translatable {

    @Input() cssClass: string = 'checkout';

    deliveryFreeFrom: IDeliveryFreeFrom[] = [];
    deliveryFreeFromGlobal: number = null;
    minFreeDeliveryFrom: number;

    howMuchToFreeDelivery: number = null;
    howMuchToFreeDeliveryPercent: number = null;


    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(public dataSvc: DataService, public settingsService: SettingsService, private cartSvc: CartService,
                private delPaySvc: DeliveryPaymentService) {
        super(dataSvc, settingsService);

        this.cartSvc.cartContentChanged
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.handleDeliveryFreeFrom();
            });

    }

    ngOnInit(): void {
        this.getDeliveryFreeFromGlobal();
        this.getDeliveryFreeFrom();
    }

    ngOnDestroy(): void {

        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private getDeliveryFreeFrom() {
        this.delPaySvc.getDeliveryFreeFrom(this.isUserB2B)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: IDeliveryFreeFrom[]) => {
                this.deliveryFreeFrom = res;
                this.handleDeliveryFreeFrom();
            });
    }

    private getDeliveryFreeFromGlobal() {
        this.delPaySvc.getDeliveryFreeFromGlobal(this.isUserB2B)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: number) => {
                this.deliveryFreeFromGlobal = res;
                this.handleDeliveryFreeFrom();
            });
    }

    private handleDeliveryFreeFrom(): void {
        if (this.deliveryFreeFromGlobal) {
            this.minFreeDeliveryFrom = this.deliveryFreeFromGlobal;
            this.reCalculateHowMuchToFreeDelivery();
        }
        else if (this.deliveryFreeFrom && this.deliveryFreeFrom.length && this.cartSvc.cart) {
            for (let i = 0; i < this.deliveryFreeFrom.length; i++) {

                if (this.deliveryFreeFrom[i].FreeFromPrice < (this.minFreeDeliveryFrom || Number.MAX_SAFE_INTEGER)) {
                    this.minFreeDeliveryFrom = this.deliveryFreeFrom[i].FreeFromPrice;
                }
            }
            this.reCalculateHowMuchToFreeDelivery();
        }
    }

    reCalculateHowMuchToFreeDelivery() {
        if (!this.cartSvc.cart.cartContent)
            return;

        const usableProducts = this.cartSvc.cart.cartContent
            .filter(q => q.productDetail && !q.GiftProductDetail && !q.productDetail.IsIndividualDelivery);
        const cartPrice = usableProducts.reduce((previousValue, currentValue) => { return previousValue + currentValue.priceSum; }, 0);
        const cartPriceWithoutVAT = usableProducts.reduce((previousValue, currentValue) => { return previousValue + currentValue.priceSumWithOutVAT; }, 0);

        if (!usableProducts || !usableProducts.length) {
            this.howMuchToFreeDelivery = this.minFreeDeliveryFrom;
        }
        else {

            this.howMuchToFreeDelivery = this.minFreeDeliveryFrom - (this.isUserB2B ? cartPriceWithoutVAT : cartPrice);
        }

        if (this.howMuchToFreeDelivery < 0) {
            this.howMuchToFreeDelivery = 0;
            this.howMuchToFreeDeliveryPercent = 100;
            return;
        }

        if (cartPrice == 0) {
            this.howMuchToFreeDeliveryPercent = 0;
            return;
        }

        this.howMuchToFreeDeliveryPercent = (cartPrice / this.minFreeDeliveryFrom) * 100;
        if (this.howMuchToFreeDeliveryPercent > 100) {
            this.howMuchToFreeDeliveryPercent = 100;
        }
    }



}