import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    OnDestroy, Renderer2,
    Type,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Subject} from "rxjs";
import {GeneralDialogInsertionDirective} from "./general-dialog-insertion.directive";
import {GeneralDialogRef} from "./common";

@Component({
    selector: 'cmp-general-dialog',
    templateUrl: '../../tpl/general-dialog.html',
  //  styleUrls: ['../../assets/styles/2-components/modal-window.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GeneralDialogComponent implements AfterViewInit, OnDestroy {
    public componentRef: ComponentRef<any>;

    @ViewChild(GeneralDialogInsertionDirective) insertionPoint: GeneralDialogInsertionDirective;
    // zrusil jsem static: true, snad to nebude vadit protoze se to stejne vola az v afterViewInit

    private readonly _onClose = new Subject<any>();
    public onClose = this._onClose.asObservable();

    public childComponentType: Type<any>;

    cssClassModifier: string;
    title: string;
    isClosable: boolean;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private cd: ChangeDetectorRef,
                private dialogRef: GeneralDialogRef,
                private rend: Renderer2) {

    }

    ngOnInit(): void {
        const html = document.querySelector('html');
        this.rend.addClass(html, 'locked');
    }

    ngAfterViewInit() {
        this.loadChildComponent(this.childComponentType);
        this.cd.detectChanges();
    }

    ngOnDestroy(): void {
        const html = document.querySelector('html');
        this.rend.removeClass(html, 'locked');
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    loadChildComponent(componentType: Type<any>) {
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

        let viewContainerRef = this.insertionPoint.viewContainerRef;
        viewContainerRef.clear();

        this.componentRef = viewContainerRef.createComponent(componentFactory);
    }

    onOverlayClicked(evt: MouseEvent) {
        evt.stopPropagation();
        this.dialogRef.close();
    }

    onDialogClicked(evt: MouseEvent) {
        evt.stopPropagation();
    }

    closeDialog() {
        if(this.isClosable) {
            this.dialogRef.close(null);
        }
    }
}

