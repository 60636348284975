import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CookiesConsentComponent} from "./cookies-consent.component";
import {ReactiveFormsModule} from "@angular/forms";
import {CookiesListComponent} from "./cookies-list.component";
import {JsScriptsComponent} from "./js-scripts.component";
import { CookiesPanelComponent } from './cookies-panel/cookies-panel.component';
import {RouterModule} from "@angular/router";


@NgModule({
    imports: [CommonModule, ReactiveFormsModule, RouterModule],
    declarations: [
        CookiesConsentComponent,
        CookiesListComponent,
        JsScriptsComponent,
        CookiesPanelComponent
    ],
    exports: [
        CookiesConsentComponent,
        CookiesListComponent,
        JsScriptsComponent,
        CookiesPanelComponent
    ],
    entryComponents:[
        CookiesConsentComponent
    ]
})

export class CookiesConsentModule {
}