import {ReplaySubject} from "rxjs";

export enum CookiesConsentStatus {
    granted = "granted",
    denied = "denied"
}

export class CookiesConsent {
    date: Date = null;
    gtmConsent: GtmConsent = new GtmConsent();
}

export class GtmConsent {
    readonly functionality_storage: CookiesConsentStatus = CookiesConsentStatus.granted;
    ad_storage: CookiesConsentStatus = CookiesConsentStatus.denied;
    analytics_storage: CookiesConsentStatus = CookiesConsentStatus.denied;
    personalization_storage: CookiesConsentStatus = CookiesConsentStatus.denied;
    security_storage: CookiesConsentStatus = CookiesConsentStatus.denied;
    ad_personalization: CookiesConsentStatus = CookiesConsentStatus.denied;
    ad_user_data: CookiesConsentStatus = CookiesConsentStatus.denied;

    wait_for_update: number = 2000;

    setAllGranted() {
        this.ad_storage = CookiesConsentStatus.granted;
        this.analytics_storage = CookiesConsentStatus.granted;
        this.personalization_storage = CookiesConsentStatus.granted;
        this.security_storage = CookiesConsentStatus.granted;
        this.ad_personalization = CookiesConsentStatus.granted;
        this.ad_user_data = CookiesConsentStatus.granted;
    }


    setDefault() {
        this.ad_storage = CookiesConsentStatus.denied;
        this.analytics_storage = CookiesConsentStatus.denied;
        this.personalization_storage = CookiesConsentStatus.denied;
        this.security_storage = CookiesConsentStatus.denied;
        this.ad_personalization = CookiesConsentStatus.denied;
        this.ad_user_data = CookiesConsentStatus.denied;

    }
}

export interface CookiesConsentGroupSelector {
    id: number;
    code: string;
    isPreselected: boolean;
    name: string;
    description: string;
    cookiesCount: number;
    isShown: boolean;
}


export interface CookiesConsentCookieSelector {
    id: number;
    groupId: number;
    groupCode: string;
    scriptId: number;
    script: string;
    name: string;
    vendor: string;
    maxAge: number;//seconds
    type: CookieConsentCookieType;
    description: string;
}


export interface CookiesConsentScriptSelector {
    id: number;
    code: string;
    name: string;
    vendor: string;
    cookies: Array<CookiesConsentCookieSelector>;
}

export enum CookieConsentCookieType {
    cookie = 'cookie',
    httpCookie = 'httpCookie',
    localStorage = 'localStorage',
    sessionStorage = 'sessionStorage'
}


export interface CookiesConsentTexts {
    about: string;
    consentText: string;
}


export class ResetableReplaySubject<T> extends ReplaySubject<T> {

    /*
    * clears the internal buffer, so all subscribers stay subscribed, but they do not receive any message
    * until another next() is called
    * */
    clearBuffer(): void {
        const that: any = this;
        that._events = [];
    }
}