/**
 * @description
 * Provides functionality for logging user in and out
 * The template of this component is the place to place Login Control GUI
 * In case of a need to implement custome validators, look here:
 * https://angular.io/docs/ts/latest/cookbook/form-validation.html#!#custom-validation
 */
import {Component, Renderer2, ViewEncapsulation} from '@angular/core';
import {DigestService} from "../../services/digest.service";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NavigationStart, Router} from "@angular/router";
import {CartService} from "../cart/cart.service";
import {isRouteSecured} from "../../helpers/string.helper";
import {Subject} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";
import {CredentialStorage} from "../../services/credential-storage.service";
import {AuthInfo, LoginEmitterMessage, Translatable} from "../../interfaces/general";

declare let sha256: any;
declare let $: any;

@Component({
    selector: 'cmp-login',
    templateUrl: '../../tpl/login.html',
    styleUrls: ['../../../assets/styles/3-layout/header/user.scss'],
    encapsulation: ViewEncapsulation.None
})

export class LoginComponent extends Translatable {

    displayUser: string;
    loggedIn: boolean = false;
    errorMessage: string;
    allowPermanent: boolean;
    userForm: FormGroup;
    mailPat: RegExp;
    showModal: boolean = false;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(dataSvc: DataService, seSvc: SettingsService, public digestSvc: DigestService,
                private fb: FormBuilder, private router: Router, private cartSvc:CartService,
                private rend: Renderer2) {
        super(dataSvc, seSvc);

        this.mailPat = this.seSvc.settings.validationPatterns.email;

        let ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            this.loggedIn = ai.loggedIn;
            if (this.loggedIn) this.displayUser = ai.displayName;
        }

        this.digestSvc.loginStatus
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: LoginEmitterMessage) => {
                if (res.type === 'error') this.errorMessage = this.sen['login-error'];
                else this.errorMessage = null;
            });
    }

    /**
     * @description
     * Calls {@link DigestService} to log the user in. Within this procedure Digest authentication scheme is being
     * carried out.
     */
    login(): void {
        this.digestSvc.login(this.userForm.get('userName').value, sha256(this.userForm.get('password').value));
    }

    /**
     * @description
     * Logs user off by deleting Authorization header info from session / local
     * You can optionally show a message
     */
    logOut(): void {
        this.cartSvc.emptyCart;
        this.digestSvc.logOut(true);
    }

    /**
     * @description
     * If checked the user is logged in across multiple sessions otherwise we log him just for one session
     */
    // setPermanent(value: boolean) {
    //     this.digestSvc.storage.permanent = value;
    // }

    createForm(): void {
        this.userForm = this.fb.group({
            userName: ['', [Validators.required, Validators.minLength(4), Validators.pattern(this.mailPat)]],
            password: ['', [Validators.required, Validators.minLength(4)]]
        })
    }

    ngOnInit(): void {

        this.createForm();
        this.allowPermanent = this.seSvc.settings.allowPermanentLogin;

        this.router.events
            .pipe(
                filter(f => f instanceof NavigationStart),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((res: NavigationStart) => {
                if (!isRouteSecured(res.url)) return;

                let ai: AuthInfo = CredentialStorage.authInfo;
                if (ai && !/^\/odhlaseni$/.test(res.url)) {
                    if (!CredentialStorage.isAiTimeValid()) {
                        this.digestSvc.logOut();
                    }
                }
        })
    }

    closeDialog(): void {
        const html = document.querySelector('html');
        this.rend.removeClass(html, 'locked');
        this.seSvc.loginOpen = false;
    }

    showDialog(): void {
        const html = document.querySelector('html');
        this.rend.addClass(html, 'locked');
        this.seSvc.loginOpen = true;
        setTimeout(() => {
            $('#login').focus();
        }, 0);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onKey(evt: KeyboardEvent ): void {
        if (evt.key === 'Enter' || evt.key=='NumpadEnter') {
            if (this.userForm.valid) {
                this.login();
            }
        }
    }

}