/**
 * Module for breadcrumbs support.
 */
import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {BreadcrumbComponent} from "./breadcrumb.component";
import {RouterModule} from "@angular/router";

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [BreadcrumbComponent],
    exports: [BreadcrumbComponent]
})

export class BreadcrumbsModule {}