import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CookieWarningComponent} from "./cookie-warning.component";

@NgModule({
    imports: [CommonModule],
    declarations: [CookieWarningComponent],
    exports: [CookieWarningComponent]
})

export class CookieWarningModule {
}