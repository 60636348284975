/**
 * This component can be used to detect the end of *ngFor iteration.
 * See it in action in {@link ProductDetailComponent}
 */
import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'cmp-last',
    template: '<ng-template></ng-template>'
})

export class LastComponent implements OnInit {

    @Output()  initiated: EventEmitter<any>;

    constructor () {
        this.initiated = new EventEmitter()
    }

    ngOnInit(): void {
        this.initiated.emit()
    }
}