import {Directive, ViewContainerRef} from "@angular/core";

@Directive({
    selector: '[generalDialogInsertion]',
})
export class GeneralDialogInsertionDirective {
    constructor(public viewContainerRef: ViewContainerRef) {

    }
}
