/**
 * Component providing functionality of a full article detail.
 */
import {Component, Input} from '@angular/core';
import {Translatable, ArticleMenuSectionSelector} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Component({
    templateUrl: '../../tpl/article-list-header.html',
    selector: 'cmp-article-list-header'
})

export class ArticleListHeaderComponent extends Translatable {

    @Input() articleSectionIds: number[];

    articles: ArticleMenuSectionSelector[];
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, seSvc: SettingsService, private http: HttpClient) {
        super(dataSvc, seSvc);
    }

    getArticles(): void {
        const qs = this.prepareQsFromArray();
        this.http.get<ArticleMenuSectionSelector[]>(`api/article/getFooterMenuLinks?${qs}`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (res) => {
                    this.articles = res;
                }
            );
    }

    private prepareQsFromArray(): string {
        let result: string = ``;
        this.articleSectionIds.forEach((elem, index) => {
            result += index === 0 ? `sectionIds=${elem}` : `&sectionIds=${elem}`;
        });
        return result;
    }

    ngOnInit(): void {
        this.getArticles();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}