import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FreeDeliveryProgressBarComponent} from './free-delivery-progress-bar.component';
import {DeliveryPaymentService} from "../delivery-payment/delivery-payment.service";

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    exports:[FreeDeliveryProgressBarComponent],
    declarations: [FreeDeliveryProgressBarComponent],
    providers: [DeliveryPaymentService]
})

export class FreeDeliveryProgressBarModule {
}
