import { NgModule } from '@angular/core';

import { AlertModalComponent } from './alert-modal/alert-modal.component';
import {CommonModule} from "@angular/common";
import {SvgCacheBustModule} from "../../directives/svg-cache-bust/svg-cache-bust.module";
import {CustomPipesModule} from '../pipe/custom-pipes.module';



@NgModule({
    declarations: [
        AlertModalComponent
    ],
    entryComponents: [
        AlertModalComponent
    ],
    imports: [
        CommonModule,
        SvgCacheBustModule,
        CustomPipesModule
    ],
    exports:[AlertModalComponent]
})
export class AlertModalModule { }
