import {AbstractControl, AsyncValidatorFn, ValidationErrors} from "@angular/forms";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {take} from "rxjs/operators";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class IcoValidatorService {

    constructor(
        private http: HttpClient
    ) {
    }

    public IcoValidator(userId: number): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            const o = new Observable<ValidationErrors>((ob) => {
                let request: any = {
                    Ico: control.value,
                    UserId: userId
                };

                this.http.post('api/user/ico-exists-validation', request)
                    .pipe(take(1))
                    .subscribe((res: any) => {
                        if (res.Exists) {
                            ob.next({
                                icoExists: true,
                                hasActiveAdmin: res.HasActiveAdmin
                            });
                        } else {
                            ob.next(null);
                        }

                        ob.complete();
                    });
            });

            return o;
        }

    }
    // public Exists(ico:string, userId?: number): Observable<any>{
    //     let request: any = {
    //         Ico: ico,
    //         UserId: userId
    //     };
    //
    //     return this.http.post<any>('api/user/ico-exists-validation', request);
    // }

}
