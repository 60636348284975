import {Component, ViewChild} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {AddressDialogConfig, AddressDialogMode} from "./common";
import {GeneralDialogRef} from "../general-dialog/common";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {AddressSelector, AddressType} from "../address/common";
import {FormBuilder, FormGroup} from "@angular/forms";
import {AddressService} from "../address/address.service";
import {take} from "rxjs/operators";
import {AddressComponent} from "../address/address.component";

@Component({
    selector: 'cmp-address-edit-modal',
    templateUrl: './address-edit-modal.html',
    styleUrls: [
        '../../../assets/styles/4-routes/account-edit.scss',
        '../../../assets/styles/4-routes/account-settings.scss'
    ],
})
export class AddressEditModalComponent extends Translatable {
    address: AddressSelector;
    mode: AddressDialogMode = "View";
    addressType: AddressType = 'Delivery';
    customReadOnly: string[] = [];
    addressForm: FormGroup;
    @ViewChild(AddressComponent) addressComponent: AddressComponent;
    constructor(dataSvc: DataService, seSvc: SettingsService,
                private config: GeneralDialogConfig<AddressDialogConfig>,
                private dialogRef: GeneralDialogRef, private fb: FormBuilder,
                private addressSvc: AddressService){
        super(dataSvc, seSvc);

        if (this.config && this.config.data) {
            this.address = this.config.data.address;
            this.mode = this.config.data.mode;
            this.addressType = this.config.data.addressType;
            this.customReadOnly = this.config.data.customReadOnly;
        }
        this.createForm();

    }
    createForm(){
        this.addressForm = this.fb.group({
            addressName:''
        });
    }

    saveAddress(){
        if(!(this.addressComponent.addressForm.invalid && this.addressComponent.addressForm.dirty))
        {
            let data = this.addressComponent.getAddress();
            this.addressSvc.upsertAddress(data)
                .pipe(take(1))
                .subscribe(res => {
                    this.address = res;
                    this.dialogRef.close(true);
                });
        }

    }
    close(){
        this.dialogRef.close(false);
    }
}
