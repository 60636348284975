import {ModuleWithProviders}  from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RouteGuardService} from "../../services/route-guard.service";

/**
 * IMPORTANT:
 * Do NOT forget to add a RegEx for the route to "FlexMvc/Helpers/RouteEval.Helper.cs"
 * Do NOT forget to test it!!!
 */
export const routes: Routes = [
    {path: '', loadChildren: 'app/modules/index/index.module#IndexModule', pathMatch: 'full'},
    {path: 'pa', loadChildren: 'app/modules/pre-auth/pre-auth.module#PreAuthModule'},
    {path: 'c', loadChildren: 'app/modules/category/category.module#CategoryModule'},
    {path: 'kategorie', loadChildren: 'app/modules/super-category/super-category.module#SuperCategoryModule'},
    {path: 'd', loadChildren: 'app/modules/product/product.module#ProductModule'},
    {path: 'p', loadChildren: 'app/modules/producers/producers.module#ProducersModule'},
    {path: 'registrace', loadChildren: 'app/modules/register-b2c/register-b2c.module#RegisterB2CModule'},
    {path: 'clanek', loadChildren: 'app/modules/articles/article-detail.module#ArticleDetailModule'},
    {path: 'clanky', loadChildren: 'app/modules/article-list/article-list.module#ArticleListModule'},
    //{path: 'blog', loadChildren: 'app/modules/blog/blog.module#BlogModule'},
    {path: 'blog', loadChildren: 'app/modules/article-list/article-list.module#ArticleListModule'},
    {path: 'upozorneni', loadChildren: 'app/modules/warnings/warnings.module#WarningsModule'},
    {path: 'muj-ucet', loadChildren: 'app/modules/account/account.module#AccountModule', canActivate: [RouteGuardService]},
    {path: 'kosik', loadChildren: 'app/modules/cart/cart.module#CartModule'},
    {path: 'zapomenute-heslo', loadChildren: 'app/modules/forgotten-password/forgotten-password.module#ForgottenPasswordModule'},
    {path: 'vysledky-vyhledavani', loadChildren: 'app/modules/search-result/search-result.module#SearchResultModule'},
    {path: 'novinky-potvrzeni', loadChildren: 'app/modules/newsletters/newsletter-confirmation.module#NewsLetterConfirmationModule'},
    {path: 'novinky-zruseni', loadChildren: 'app/modules/newsletters/newsletter-unregistration.module#NewsLetterUnregistrationModule'},
    {path: 'chyba', loadChildren: 'app/modules/warnings/warnings.module#WarningsModule'},

    // preparing GP WebPay routes
    // {path: 'gpwebpay', loadChildren: 'app/modules/cart/cart.module#CartModule'},
    {path: 'porovnat', loadChildren: 'app/modules/compare/compare.module#CompareModule'},
    {path: 'vyprodej', loadChildren: 'app/modules/product-list-attr/product-list-attr.module#ProductListAttrModule'},
    {path: 'akcni', loadChildren: 'app/modules/product-list-attr/product-list-attr.module#ProductListAttrModule'},
    {path: 'novinky', loadChildren: 'app/modules/product-list-attr/product-list-attr.module#ProductListAttrModule'},
    {path: 'akce', loadChildren: 'app/modules/banners/banners.module#BannersModule'},
    // DO NOT ADD THIS TO "FlexMvc/Helpers/RouteEval.Helper.cs"
    {path: '**', redirectTo: '/chyba/404'}
];

export const appRoutingProviders: any[] = [
    RouteGuardService
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled"
});
