import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {IAresAddress, TAresAddressSource, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CompanySelector} from "./common";
import {finalize, takeUntil} from "rxjs/operators";
import {AresService} from "../../services/ares.service";
import {Subject} from "rxjs";
import {IcoValidatorService} from "../../services/ico-validator.service";


@Component({
    selector: 'cmp-company',
    templateUrl: '../../tpl/company.html'
})

export class CompanyComponent extends Translatable implements OnChanges {
    @Input() validateIco: boolean = false;
    @Input() company: CompanySelector;
    @Input() cssClassPrefix: string = 'user-data';
    @Input() readonly: boolean = false;
    @Input() checkCrnInAres: boolean = false;
    @Output() changes: EventEmitter<CompanySelector> = new EventEmitter<CompanySelector>();
    @Output() aresAddress: EventEmitter<IAresAddress> = new EventEmitter<IAresAddress>();

    @Output() icoAndAdminExists: EventEmitter<any> = new EventEmitter<any>();
    companyForm: FormGroup;
    aresLock: boolean = false;
    crnLoading: boolean = false;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(dataSvc: DataService, seSvc: SettingsService, private fb: FormBuilder, private aresSvc: AresService, public icoSvc: IcoValidatorService) {
        super(dataSvc, seSvc)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['company']) {
            this.company = changes['company'].currentValue;
            this.createForm()
            this.SetValidators();
        }
    }

    getCompany(): CompanySelector {
        return {
            name: this.companyForm.get('name').value,
            ic: this.companyForm.get('ic').value,
            dic: this.companyForm.get('dic').value,
            newsletter: this.companyForm.get('newsletter').value
        }
    }

    forceDirty(): void {
        for (let obj in this.companyForm.controls) {
            (this.companyForm.controls[obj] as AbstractControl).markAsDirty();
        }
    }

    private createForm(): void {
        if (!this.company) {
            this.company = {
                id: 0,
                ic: '',
                dic: '',
                name: '',
                newsletter: false
            }
        }
        this.companyForm = this.fb.group({
            name: [this.company.name, [Validators.required]],
            ic: [this.company.ic, [Validators.required]],
            dic: [this.company.dic],
            newsletter: [this.company.newsletter]
        });
        this.companyForm.valueChanges.subscribe(() => {
            this.changes.emit(this.getCompany());
        });

        this.companyForm.get('ic').statusChanges.subscribe(()=>{
            let exists = !!(this.companyForm.controls['ic'].errors != null && this.companyForm.controls['ic'].errors['icoExists']);
            let hasActiveAdmin = !!(this.companyForm.controls['ic'].errors != null && this.companyForm.controls['ic'].errors['hasActiveAdmin']);
            this.icoAndAdminExists.emit({exists: exists, hasActiveAdmin: hasActiveAdmin});


        });
        this.aresLock = false;
    }
    public SetValidators(): void {
        if (this.validateIco) {
            this.companyForm.get('ic').setAsyncValidators([this.icoSvc.IcoValidator(null)]);
            this.companyForm.get('ic').updateValueAndValidity({emitEvent: false});
        }
    }
  /*  onAresDetail(ares: IAresDetail): void {
        if (ares && !ares.Error) {
            this.companyForm.get('name').setValue(ares.BusinessName, {emitEvent:false, onlySelf:true});
            this.aresLock = true;

            this.aresAddress.emit(ares.Address);
        }
        else {
            this.aresLock = false;
            this.aresAddress.emit(null);
        }

    }*/
    onChange(){
        let ico = this.companyForm.get('ic').value as string;
        if (ico == null || ico.length == 0) {
            this.aresLock = false;
            this.companyForm.reset({
                dic: {value: "", disabled: this.aresLock},
                name: {value: "", disabled: this.aresLock}
            });
            this.aresAddress.emit(undefined);
        }
    }
    triggerAres(): void {

        if (this.companyForm.get('ic').errors && this.companyForm.get('ic').errors.crnNumber)
        {
          //  this.companyForm.get('ic').markAsDirty();
            return;
        }
        let ic = this.companyForm.get('ic').value
        ic = ic.trim();
        this.dataSvc.dataLoading = true;
        this.aresSvc.getAresSubject(ic)
            .pipe(
                finalize(() => {
                    // this.setAresReadOnly();
                    this.dataSvc.dataLoading = false;
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((ares) => {
                if (ares && !ares.Error) {
                    // this.companyForm.get('name').setValue(ares.BusinessName, {emitEvent:false, onlySelf:true});
                    this.companyForm.patchValue({
                        name: ares.BusinessName,
                        dic: ares.Dic ? ares.Dic : ' ',
                    }, {emitEvent: false, onlySelf: true})
                    this.aresLock = true;

                    this.company.ic = ares.RegNo;
                    this.company.dic = ares.Dic;
                    this.company.name = ares.BusinessName;

                    this.aresAddress.emit(this.processAresAddress(ares.Address, 'ARES'));
                    this.companyForm.get('dic').disable();
                    this.companyForm.get('name').disable();
                } else {
                    this.aresLock = false;
                    this.company.ic = ares.RegNo;
                    this.company.dic = null;
                    this.company.name = null;
                    this.aresAddress.emit({Source: 'ARES', Valid: false});
                    this.companyForm.reset({
                        dic: {value: this.company.dic, disabled: this.aresLock},
                        name: {value: this.company.name, disabled: this.aresLock},
                        ic: this.company.ic
                    });
                }
                //    if (!this.companyForm.invalid) {
                /*       this.companyForm.reset({
                           dic: {value: this.company.dic, disabled: this.aresLock},
                           name: {value: this.company.name, disabled: this.aresLock},
                           ic: this.company.ic
                       });*/
                // }



            }, () => {
                this.companyForm.reset({
                    dic: {value: this.company.dic, disabled: this.aresLock},
                    name: {value: this.company.name, disabled: this.aresLock},
                    ic: this.company.ic
                });
                this.aresLock = false;
                this.aresAddress.emit({Source: 'ARES', Valid: false});
            });

    }
    private processAresAddress(address: IAresAddress, source: TAresAddressSource): IAresAddress {
        return {
            Source: source,
            Valid: true,
            Country: address.Country,
            City: address.City,
            CityPart: address.City != address.CityPart ? address.CityPart : '',
            HouseNumber: address.HouseNumber,
            Street: address.Street || '',
            StreetNumber: address.StreetNumber,
            ZipCode: address.ZipCode,
            CurrencyId: address.CurrencyId
        };
    }


    setAresReadOnly(): void {
        for (let controlsKey in this.companyForm.controls) {
            if (this.companyForm.controls[controlsKey].value && this.aresLock && controlsKey != 'ic') {
                this.companyForm.controls[controlsKey].disable();
            } else {
                //this.companyForm.controls[controlsKey].enable();
            }
        }
    }
}