/**
 * Created by jan.hroch on 27.06.2017.
 */
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Culture, SearchSelector, Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {RenderStyle} from "../product/common";

@Component({
    selector: 'cmp-search-suggest',
    templateUrl: '../../tpl/search-suggest.html'
})

export class SearchSuggestComponent extends Translatable {

    @Input() suggestResult: SearchSelector;
    @Output() queryHide: EventEmitter<boolean>;
    @Input() phrase: string;
    imagePathPrefix: string;
    culture: Culture;
    decimalPattern: string = '1.0-0';
    renderStyles = RenderStyle;

    constructor(dataSvc: DataService, seSvc: SettingsService) {
        super(dataSvc, seSvc);

        this.decimalPattern = `1.${this.seSvc.settings.decimalSettings.ProductBox}-${this.seSvc.settings.decimalSettings.ProductBox}`;
        this.imagePathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/suggest/images/';
        this.queryHide = new EventEmitter();
        this.culture = this.seSvc.culture;
    }

    ngOnChanges() {
        if (this.suggestResult){
            if(this.suggestResult.products && this.suggestResult.products.length > 0) this.suggestResult.products.forEach(p => p.ImagePath = this.appendCBK(this.imagePathPrefix + p.ImagePath, p.ImagePath));
        }
    }

    private appendCBK(fullPath: string, croppedPath: string): string{
        if(fullPath.includes(this.seSvc.settings.imageServerPathPrefix) && !croppedPath.includes("cbk=")) {
            const sep = /\?/.test(fullPath) ? '&' : '?';
            return croppedPath + `${sep}cbk=${this.seSvc.cacheBustKey}`;
        }
        return croppedPath;
    }

    linkClicked(): void {
        this.queryHide.emit(true);
    }

    onComponentClick(evt: any): void {
        evt.stopPropagation();
    }

}