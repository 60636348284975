import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {Country, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {AddressSelector, AddressType} from "./common";

@Component({
    selector: 'cmp-address',
    templateUrl: '../../tpl/address.html'
})

export class AddressComponent extends Translatable implements OnChanges {

    @Input() id: string;
    @Input() cssClassPrefix: string = 'user-data';
    @Input() addressType: AddressType;
    @Input() address: AddressSelector;
    @Input() readOnly: boolean;
    @Input() aresLock: boolean = false;
    @Input() withContact: boolean = true;
    @Input() withCompany: boolean = false;
    @Input() customReadOnly: string[] = [];
    @Output() changes: EventEmitter<AddressSelector> = new EventEmitter<AddressSelector>();

    addressForm: FormGroup;
    emailPattern: any;
    phonePattern: any;
    zipCodePattern: any;
    countries: Country[];
    warningText: string;
    editableControlsHandled: boolean;

    constructor(public dataSvc: DataService, public seSvc: SettingsService, private fb: FormBuilder) {
        super(dataSvc, seSvc);

        this.countries = seSvc.settings.countries;
        this.emailPattern = this.seSvc.settings.validationPatterns.email;
        this.phonePattern = this.seSvc.settings.validationPatterns.phone[this.seSvc.culture.cultureId];
        this.zipCodePattern = this.seSvc.settings.validationPatterns.zipCodeCz;

        this.createForm();
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes['address']) {
            this.address = {...this.emptyModel(), ...changes['address'].currentValue};
            this.addressForm.reset(this.address);
        }
        if (changes['customReadOnly']) {
            this.setValidators();
        }
        if (changes['withContact']) {
            if (this.withContact) {
                this.handleEditableControls();
            } else {
                this.addressForm.controls['firstName'].setValidators(null);
                this.addressForm.controls['lastName'].setValidators(null);
                this.addressForm.controls['phone'].setValidators(null);
                this.addressForm.controls['email'].setValidators(null);
            }
            this.addressForm.updateValueAndValidity();
        }
        if (changes['aresLock']) {
            if (this.addressForm) {
                if (changes['aresLock'].currentValue) {
                    this.addressForm.get('city').disable();
                    this.addressForm.get('street').disable();
                    this.addressForm.get('zipCode').disable();
                    this.addressForm.get('countryId').disable();
                } else {
                    this.addressForm.get('city').enable();
                    this.addressForm.get('street').enable();
                    this.addressForm.get('zipCode').enable();
                    this.addressForm.get('countryId').enable();
                }
            }
        }
    }

    private handleEditableControls(): void {
        this.addressForm.setValidators([this.allOrNoneValidator(['firstName', 'lastName', 'phone', 'email'])]);
        this.addressForm.updateValueAndValidity();
    }

    getAddress(): AddressSelector {
        let cid = +this.addressForm.get('countryId').value;
        let country = cid > 0 ? this.countries.filter(f => f.id === cid)[0].name : null;

        return {
            id: this.addressForm.get('id').value,
            countryId: cid,
            country: country,
            addressType: this.addressType,
            company: this.addressForm.get('company').value,
            city: this.addressForm.get('city').value,
            firstName: this.addressForm.get('firstName').value,
            lastName: this.addressForm.get('lastName').value,
            street: this.addressForm.get('street').value,
            zipCode: this.addressForm.get('zipCode').value,
            phone: this.addressForm.get('phone').value,
            email: this.addressForm.get('email').value,
            disabled: false,
            writtenInAccountancy: false
        };
    }

    forceDirty(): void {
        for (let obj in this.addressForm.controls) {
            (this.addressForm.controls[obj] as AbstractControl).markAsDirty();
        }
    }

    isReadOnly(formControl: string) {
        return this.customReadOnly.includes(formControl);
    }

    public allOrNoneValidator(controls: string[]): ValidatorFn {
        return (group: AbstractControl): { [key: string]: any } | null => {
            const formGroup = group as FormGroup;
            const hasAnyValue = controls.some(controlName => !!formGroup.get(controlName).value);
            const allFilled = controls.every(controlName => !!formGroup.get(controlName).value);

            return hasAnyValue && !allFilled ? { allOrNone: true } : null;
        };
    }

    private createForm(): void {

        if (!this.address) {
            this.address = this.emptyModel();
        }

        this.addressForm = this.fb.group({
                id: [this.address.id],
                company: [this.address.company, [Validators.required, Validators.maxLength(100)]],
                firstName: [{
                    value: this.address.firstName,
                    disabled: false
                }, [Validators.maxLength(50)]],
                lastName: [{
                    value: this.address.lastName,
                    disabled: false
                }, [Validators.maxLength(100)]],
                street: [{value: this.address.street, disabled: false}, [Validators.required, Validators.maxLength(100)]],
                city: [{value: this.address.city, disabled: false}, [Validators.required, Validators.maxLength(100)]],
                zipCode: [{
                    value: this.address.zipCode,
                    disabled: false
                }, [Validators.required, Validators.pattern(this.zipCodePattern)]],
                countryId: [{value: this.address.countryId, disabled: false}, [Validators.required]],
                phone: [{value: this.address.phone, disabled: false}, [Validators.maxLength(100), Validators.pattern(this.phonePattern)]],
                email: [{value: this.address.email, disabled: false}, [Validators.maxLength(100), Validators.pattern(this.emailPattern)]],

            },
        );


        this.addressForm.valueChanges.subscribe(() => {
            const formErrors = this.addressForm.errors;
            if (formErrors && formErrors.allOrNone) {
                this.warningText = this.sen['address-not-filled-all-inputs'];
                this.forceDirty();
            } else {
                this.warningText = "";
            }
            this.changes.emit(this.getAddress());
        });
    }

    setValidators() {
        if (!this.isReadOnly('countryId')) {
            this.addressForm.controls['countryId'].setValidators(Validators.required);
        } else {
            this.addressForm.controls['countryId'].setValidators(null);
        }

        this.addressForm.updateValueAndValidity();
    }

    emptyModel(): AddressSelector {
        return {
            id: 0,
            countryId: this.countries[0].id,
            addressType: this.addressType,
            company: '',
            city: '',
            firstName: '',
            lastName: '',
            street: '',
            zipCode: '',
            disabled: false,
            writtenInAccountancy: false,
            email: '',
            phone: ''
        };
    }

}