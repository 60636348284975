import {CookiesConsentService} from "../modules/cookies-consent/cookies-consent.service";

export class StorageInterceptor {
    constructor() {

    }

    static hijackLocalStorage(ccSvc: CookiesConsentService): void {
        if (!ccSvc.isStorageInterceptingActive) {
            return;
        }

        const originalLocalStorageSetItem: any = window.localStorage.setItem.bind(window.localStorage);
        const newSetItem = function (key: string, value: string) {
            ccSvc.onLocalStorageSetItem(key);
            originalLocalStorageSetItem(key, value);
        };
        newSetItem.bind(window);
        window.localStorage.setItem = newSetItem;
    }

    static hijackSessionStorage(ccSvc: CookiesConsentService): void {
        if (!ccSvc.isStorageInterceptingActive) {
            return;
        }

        const originalSessionStorageSetItem: any = window.sessionStorage.setItem.bind(window.sessionStorage);
        const newSetItem = function (key: string, value: string) {
            ccSvc.onSessionStorageSetItem(key);
            originalSessionStorageSetItem(key, value);
        };
        newSetItem.bind(window);
        window.sessionStorage.setItem = newSetItem;
    }
}