/**
 * Component providing functionality of a full article detail.
 */
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Translatable, ArticleSelector, PagedResponse, IDateSettings} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

declare let moment: any;

@Component({
    templateUrl: '../../tpl/article-list-news-index.html',
    selector: 'cmp-article-list-index'
})

export class ArticleListNewsIndexComponent extends Translatable {

    @Input() articleSectionId: number;
    @Input() pageSize: number;
    @Output() noItemsFound: EventEmitter<boolean> = new EventEmitter();

    articleImagePathPrefix: string;
    articles: ArticleSelector[];
    dateSettings: IDateSettings;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, seSvc: SettingsService,
                private http: HttpClient) {
        super(dataSvc, seSvc);

        this.articleImagePathPrefix = seSvc.settings.imageServerPathPrefix + '/fotocache/artnw/images/';
        this.dateSettings = this.seSvc.settings.dateSettings[this.seSvc.culture.cultureId];
    }

    getNews(): void {
        this.http.get<PagedResponse<ArticleSelector>>(`api/article/getArticleChildrenPaged/${this.articleSectionId}/1/${this.pageSize}`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                res => {
                    this.articles = res.data;
                    this.articles.forEach((elem: ArticleSelector) => {
                        elem.imagePath = elem.imagePath ? this.articleImagePathPrefix + elem.imagePath : null;
                        elem.stringCreated = elem.dateCreated && moment(elem.dateCreated).format(this.dateSettings.DatePattern)
                        elem.stringLastUpdated = elem.dateLastUpdated && moment(elem.dateLastUpdated).format(this.dateSettings.DatePattern)
                    });

                    if (!res || res.data.length === 0) this.noItemsFound.emit(true);
                }, () => {
                    this.noItemsFound.emit(true);
                }
            );
    }

    ngOnInit(): void {
        this.getNews();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}