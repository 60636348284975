import {Component, Input} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {getCookie, setCookie} from "../../helpers/cookie.helper";

@Component({
    selector: 'cmp-cookie-warning',
    templateUrl: '../../tpl/cookie-warning.html'
})

export class CookieWarningComponent extends Translatable {

    @Input() DelayToShow: number = 3000;
    @Input() ExpireInHours: number = 0;

    showWarning: boolean = false;
    delayHandler: any = null;

    constructor(public dataSvc: DataService, public seSvc: SettingsService) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
        let wc: string = getCookie('warcoo');
        if (wc != '1') {
            if (this.delayHandler) clearTimeout(this.delayHandler);
            this.delayHandler = setTimeout(() => {
                this.showWarning = true;
            }, Math.max(0, this.DelayToShow));
        }
    }

    cookieAccept(): void {
        if (this.ExpireInHours > 0) {
            setCookie('warcoo', '1', this.ExpireInHours);
        } else {
            setCookie('warcoo', '1');
        }
        this.showWarning = false;
    }

    ngOnDestroy() {
        if (this.delayHandler) clearTimeout(this.delayHandler);
    }

}