﻿import {Component} from '@angular/core';
import {IDomainInfo, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {GeneralDialogRef} from "../general-dialog/common";

@Component({
    selector: 'cmp-popup-b2b',
    templateUrl: '../../tpl/popup-b2b.html'
})

export class PopupB2bComponent extends Translatable {
    constructor(public dataSvc: DataService, seSvc: SettingsService,
                private dialogRef: GeneralDialogRef) {
        super(dataSvc, seSvc);
    }


    get isShopB2B(): boolean {
        return this.seSvc.domainInfo.b2b;
    }

    get domainInfo(): IDomainInfo {
        return this.seSvc.domainInfo;
    }

    navigate(domain: string): void {
        window.location.href = '//' + domain;
        this.dialogRef.close();
    }
    close(){
        this.seSvc.visitedPopUpB2B = true
        this.dialogRef.close();
    }

}