/**
 * @description
 * This service provides all common data fetching operations
 * Do not use for route specific fetching - e.g. product catalogue should fetch via it's own data service
 * to scale the file size of the JS
 */
import {EventEmitter, Injectable} from "@angular/core";
import {AuthInfo, BcInfo, IGAEventFields} from "../interfaces/general";
import {Title, Meta} from "@angular/platform-browser";
import {SharedAppSettings} from "../shared-settings/shared-settings";
import {loadFromSession} from "../helpers/cookie.helper";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {CartSelector, IGAItem, IGATransaction} from "../modules/cart/common";

declare let ga: Function;

@Injectable()
export class DataService {
    private _userDisplayName: string;
    get userDisplayName(): string {
        return this._userDisplayName;
    }

    set userDisplayName(value: string) {
        this._userDisplayName = value;
    }

    private _categoryId: number;
    get categoryId(): number {
        return this._categoryId;
    }

    set categoryId(value: number) {
        this._categoryId = value;
    }

    private _dataLoading: number = 0;
    get dataLoading(): boolean {
        return this._dataLoading > 0;
    }

    set dataLoading(value: boolean) {
        if (value) {
            this._dataLoading++;
        }
        else {
            this._dataLoading--;
        }
        if (this._dataLoading < 0) {
            this._dataLoading = 0;
        }
    }

    private _mainMenuHidden: boolean;
    get mainMenuHidden(): boolean {
        return this._mainMenuHidden;
    }

    set mainMenuHidden(value: boolean) {
        this._mainMenuHidden = value;
    }

    private _bcHidden: boolean;
    get bcHidden(): boolean {
        return this._bcHidden;
    }

    set bcHidden(value: boolean) {
        this._bcHidden = value;
    }

    private _paHidden: boolean;
    get paHidden(): boolean {
        return this._paHidden;
    }

    set paHidden(value: boolean) {
        this._paHidden = value;
    }

    breadcrumbChanged: EventEmitter<BcInfo[]>;
    private bc: BcInfo[];
    domain: string;

    constructor(private sapSvc: SharedAppSettings, private titSvc: Title,
                private metaService: Meta, private router: Router) {

        this.breadcrumbChanged = new EventEmitter();
        this.domain = this.sapSvc.Defaults.Domain;

        let ai = <AuthInfo>loadFromSession('ai');
        if (ai) {
            this.userDisplayName = ai.displayName;
        }

        /**
         * hiding main menu in cart
         */
        this.router.events.subscribe(res => {

            if (res instanceof NavigationEnd && res.urlAfterRedirects) {

                this.mainMenuHidden = /^\/kosik\//.test(res.urlAfterRedirects);
                this.bcHidden = /^\/$/.test(res.urlAfterRedirects);
                /**
                 * setting rel="canonical" - in case there is none in the data
                 * for given component
                 */
                let url = res.urlAfterRedirects;
                document.querySelector('link[rel="canonical"]').setAttribute('href', `${this.domain}${url}`);
                /**
                 * removing <meta name="robots" content="noindex">
                 * this one is added by {@link ErrorComponent} to block indexing of the 404 page
                 * @type {HTMLMetaElement}
                 */
                let robotsElement: HTMLMetaElement = this.metaService.getTag(`name="robots"`);
                if (robotsElement) this.metaService.removeTagElement(robotsElement);
                /**
                 * setting and sending GA
                 */
                (<any>window).ga('set', 'page', res.urlAfterRedirects);
                (<any>window).ga('send', 'pageview');
            }

            if (res instanceof NavigationStart) {
                /**
                 * used for hiding/displaying elements on /pa route
                 * this is needed cos of z-index hierarchy on app.html
                 */
                this.paHidden = /^\/pa$/.test(res.url);
            }
        });
    }

    setBc(bcInfo: BcInfo[], seoTitle?: string): void {

        this.bc = bcInfo;
        if (bcInfo.length > 0) {
            let title = seoTitle || `${this.sapSvc.Defaults.TitlePrefix} - ${bcInfo[bcInfo.length - 1].displayName}`;
            this.titSvc.setTitle(title);
        }
        this.breadcrumbChanged.emit(this.bc);
    }

    setPageHead(description: string, seoUrl: string, seoKeyWords?: string): void {
        /**
         * setting rel="canonical"
         * with the one retrieved from entity data in component
         */
        document.querySelector('link[rel="canonical"]').setAttribute('href', `${seoUrl}`);
        /**
         * setting <meta name="description" content="something">
         */
        const metaDesc = description || '';
        if (metaDesc) {
            if (this.metaService.getTag('name=description')) {
                this.metaService.updateTag({name: 'description', content: metaDesc});
            } else {
                this.metaService.addTag({name: 'description', content: metaDesc});
            }
        } else {
            this.metaService.removeTag('name=description');
        }

        if (seoKeyWords) {
            if (this.metaService.getTag('name=keywords')) {
            this.metaService.updateTag({name: 'keywords', content: seoKeyWords || ''});
        } else {
            this.metaService.addTag({name: 'keywords', content: seoKeyWords || ''});
        }
        } else {
            this.metaService.removeTag('name=keywords');
        }
    }

    setTitle(text: string): void{
        this.titSvc.setTitle(text);
    }

    setNoIndex(): void {
        this.metaService.addTag({name: 'robots', content: 'noindex'});
    }

    appendLinkedData(data: any, elId: string = 'mainEntityData'): void {
        let head = document.querySelector('head');
        let scriptElement: HTMLScriptElement = <HTMLScriptElement>document.querySelector(`script[type="application/ld+json"][id="${elId}"]`);
        if (!scriptElement) {
            scriptElement = document.createElement('script');
            scriptElement.type = 'application/ld+json';
            scriptElement.id = elId;
            scriptElement.text = JSON.stringify(data);
            head.appendChild(scriptElement);
        } else {
            scriptElement.text = JSON.stringify(data);
        }
    }

    public googleEcomerceSend(gaId:string, order: CartSelector, orderNumber: number): void {
        if (gaId) {
            let compPrice: number = 0;
            order.cartContent.forEach(oi => {
                compPrice += oi.priceAfterCouponApplied;
            });

            var transaction = <IGATransaction>{
                id: orderNumber.toString(),
                revenue: compPrice,
                currency: 'CZK'
            };
            if (order.delivery.Price > 0) {
                transaction.shipping = order.delivery.Price;
            }
            if (order.payment.priceWithVat > 0) {
                transaction.shipping += order.payment.priceWithVat;
            }

            ga('require', 'ecommerce');
            ga('ecommerce:addTransaction', transaction);

            order.cartContent
                .filter(x => x.productId > 0 && x.productDetail)
                .forEach(oi => {
                    ga('ecommerce:addItem',
                        <IGAItem> {
                            id: orderNumber.toString(),
                            name: oi.productDetail.Name,
                            sku: oi.productDetail.CodeDisplay,
                            price: oi.productDetail.PriceWithVAT,
                            quantity: oi.count
                        }
                    );
                });
            /*order.cartContent
                .filter(x => x.CouponId > 0 && x.CouponDetail)
                .forEach(cp => {
                    ga('ecommerce:addItem',
                        <IGAItem> {
                            id: orderNumber.toString(),
                            name: 'Slevový poukaz: ' + cp.CouponDetail.name,
                            sku: 'C-' + cp.CouponDetail.code,
                            price: -cp.Count * cp.CouponDetail.value,
                            quantity: cp.Count
                        }
                    );
                });*/
            ga('ecommerce:send');
        }
    }

    public googleEventSend(category: string, action: string, label?: string, value?: number): void {
        let eventFields = <IGAEventFields>{
            hitType: 'event',
            eventCategory: category,
            eventAction: action
        };
        if (label) {
            eventFields.eventLabel = label;
        }
        if (value) {
            eventFields.eventValue = value;
        }
        ga('send', eventFields);
    }

}

