/**
 * @description
 * Component providing validation of Company Registration Number (IČ)
 */
import {Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors} from "@angular/forms";
import {AsyncValidator} from "@angular/forms/src/directives/validators";
import {HttpClient} from "@angular/common/http";
import {Observable, Subscription} from "rxjs";

/**
 * Validation of Czech IČ
 * @param x
 * @returns {boolean}
 */
export function testCrnCz(x: any) {
    try {
        let a = 0;
        if (x.length == 0) return true;
        if (x.length != 8) return false;
        let b = x.split('');
        let c;
        for (let i = 0; i < 7; i++) a += (parseInt(b[i]) * (8 - i));
        a = a % 11;
        c = 11 - a;
        if (a == 1) c = 0;
        if (a == 0) c = 1;
        if (a == 10) c = 1;
        if (parseInt(b[7]) != c) return false;
    }
    catch (e) {
        return false;
    }
    return true;
}

@Directive({
    selector: '[aresCrnNumber]',
    providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: AresValidatorDirective, multi: true}]
})

export class AresValidatorDirective implements AsyncValidator, OnChanges {

    @Input('aresCrnNumber') active: any;
    private _onChange: () => void;
    private checkSub: Subscription;
    private delay: any;
    private defaultValue: string;

    @Output() aresDetail: EventEmitter<IAresDetail> = new EventEmitter<IAresDetail>();

    constructor(private http: HttpClient){

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['active'] && changes['active'].currentValue && changes['active'].currentValue['default']) {
            this.defaultValue = changes['active'].currentValue['default'];
        }
        if (this._onChange) {
            this._onChange();
        }
    }

    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return new Promise((resolve, reject) => {
            clearTimeout(this.delay);
            if (!control.value){
                this.aresDetail.emit(null);
                return resolve(null);
            } else if (!this.active) {
                return resolve(null);
            } else if (control.value == this.defaultValue) {
                return resolve(null);
            } else if (testCrnCz(control.value)) {
                if (this.checkSub) this.checkSub.unsubscribe();
                this.delay = setTimeout(() => {
                    clearTimeout(this.delay);
                    this.checkSub = this.http.get<IAresDetail>(`api/ares/subject/${control.value}`)
                        .subscribe((result) => {
                            if (result.Error == null) {
                                this.aresDetail.emit(result);
                                return resolve(null);
                            } else {
                                this.aresDetail.emit(null);
                                return resolve({'ares': control.value});
                            }
                        }, () => {
                            this.aresDetail.emit(null);
                            return reject({'ares': control.value});
                        });
                }, 1000);
            } else {
                this.aresDetail.emit(null);
                return resolve({'ares_format': control.value});
            }
        });
    }

    registerOnValidatorChange(fn: () => void): void {
        this._onChange = fn;
    }
}

export interface IAresDetail {
    RegNo: string;
    Error: IAresError;
    BusinessName: string;
    Address: IAresAddress;
}

export interface IAresAddress {
    Street: string;
    StreetNumber: string;
    HouseNumber?: number;
    City: string;
    CityPart: string;
    ZipCode: string;
    Country: number;
}

export interface IAresError {
    Text: string;
    Code: number;
}
