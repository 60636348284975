import {NgModule} from "@angular/core";
import {ProductPriceHandlerComponent} from "./product-price-handler.component";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [CommonModule],
    declarations: [ProductPriceHandlerComponent],
    exports: [ProductPriceHandlerComponent]
})

export class ProductPriceHandlerModule {
}