import {Injectable} from "@angular/core";
///////////////////////////////////////////

/**
 * Automatically generated based on AccountModule from SharedSettings dll
 */

@Injectable()
export class SharedAccountSettings 
    {
ContactType = {
Phone: 'Phone',
Mobile: 'Mobile',
Email: 'E-mail',
Fax: 'Fax'
};
Invoice = {
InvoiceStatus: {

}
};
Complaint = {
ComplaintStatus: {

}
}
}




/**
 * Automatically generated based on AddressModule from SharedSettings dll
 */

@Injectable()
export class SharedAddressSettings 
    {
AddressType = {
Invoice: 'Invoice',
Delivery: 'Delivery'
}
}




/**
 * Automatically generated based on AppModule from SharedSettings dll
 */

@Injectable()
export class SharedAppSettings 
    {
Defaults = {
CultureId: 34,
CurrencyId: 3,
MenuArticleId: 67,
NewsArticleId: 65,
BlogArticleId: 108,
FooterArticleIds: '57;58;102;126;128;129',
HeaderArticleIds: '96',
AllowAnonymousCart: false,
NonceTimeMinutes: 120,
PermanentLoginExpirationHours: 2160,
FacebookShareDomain: 'https://www.okentescz002.scdev.cz',
Domain: 'https://www.okentescz002.scdev.cz',
ProductionDomain: 'https://www.okentescz002.scdev.cz',
TitlePrefix: 'Okentěs s.r.o.',
GrAvatarCoreUrl: 'https://www.gravatar.com',
GoogleMerchantCategory: 2271,
FeedFolder: '/tmp/feeds',
Company: 'Okentěs s.r.o.'
}
}




/**
 * Automatically generated based on CartModule from SharedSettings dll
 */

@Injectable()
export class SharedCartSettings 
    {
CartItemType = {
PRODUCT: 'PRODUCT',
PAYMENT: 'PAYMENT',
TRANSPORT: 'TRANSPORT',
COUPON: 'COUPON',
GIFT: 'GIFT'
}
}




/**
 * Automatically generated based on CategoryModule from SharedSettings dll
 */

@Injectable()
export class SharedCategorySettings 
    {
Sorting = {
order_asc: 'order asc',
price_asc: 'price asc',
price_desc: 'price desc',
name_asc: 'name asc',
name_desc: 'name desc',
recommended_desc: 'recommended desc',
BestSelling: 'from best selling',
FromLatest: 'from latest',
ByName: 'by name'
}
}




/**
 * Automatically generated based on ProductModule from SharedSettings dll
 */

@Injectable()
export class SharedProductSettings 
    {
Flags = {
bAction: 'bAction',
bNew: 'bNew',
bSellOut: 'bSellOut',
bAvail: 'bAvail',
bDangerGoods: 'bDangerGoods',
bPersonally: 'bPersonally',
bDiscount: 'bDiscount'
};
WatchDog = {
StockMonitorTypes: {
AllChanges: 'watch-dog-all-stock-changes',
ChangeFromZero: 'watch-dog-on-stock-change-from-zero'
},
PriceMonitorTypes: {
Cheapen: 'watch-dog-cheapen',
Threshold: 'watch-dog-cheapen-under-threshold'
}
}
}

