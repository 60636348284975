
/**
 * Created by jan.hroch on 27.06.2017.
 */

import {SearchSuggestComponent} from "./search-suggest.component";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {ProductPriceHandlerModule} from "../product/product-price-handler.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ProductPriceHandlerModule
    ],
    declarations: [SearchSuggestComponent],
    exports: [SearchSuggestComponent]
})

export class SearchSuggestModule {}