/**
 * Serves article data, can be injected when needed (child injector).
 */
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ArticleSelector} from "../../interfaces/general";

@Injectable()
export class ArticleService {

    constructor (private http: HttpClient) {}

    getArticle(id: string, seoUrl:string): any {
        return this.http.get<ArticleSelector>(`api/article/${id}/${seoUrl}`);
    }

    getBreadcrumbs(id: string):any{
        return this.http.get(`api/article/breadcrumb/${id}`);
    }

}
