﻿import {Pipe, PipeTransform} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'safeHtml'})
export class DomSanitizerSafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer:DomSanitizer){}

    transform(html: string) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}

@Pipe({name: 'safeResourceUrl'})
export class DomSanitizerSafeResourceUrlPipe implements PipeTransform {
    constructor(private sanitizer:DomSanitizer){}

    transform(html: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(html);
    }
}

@Pipe({name: 'safeStyle'})
export class DomSanitizerSafeStylePipe implements PipeTransform {
    constructor(private sanitizer:DomSanitizer){}

    transform(html: string) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}

@Pipe({name: 'safeUrl'})
export class DomSanitizerSafeUrlPipe implements PipeTransform {
    constructor(private sanitizer:DomSanitizer){}

    transform(html: string) {
        return this.sanitizer.bypassSecurityTrustUrl(html);
    }
}