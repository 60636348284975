import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {ArticleSectionSelector, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";


@Component({
    templateUrl: '../../tpl/article-list-section.html',
    selector: 'cmp-article-list-section'
})

export class ArticleListSectionComponent extends Translatable implements OnChanges {

    @Input() articleSectionId: number;
    @Input() selectedIds: number[];
    @Output() filterChanged: EventEmitter<number[]> = new EventEmitter();

    catSecHidden: boolean = false;
    sections: ArticleSectionSelector[];
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, seSvc: SettingsService,
                private http: HttpClient) {
        super(dataSvc, seSvc);


    }

    ngOnInit(): void {
        this.getArticleSections();
    }

    getArticleSections(): void {
        this.http.get<ArticleSectionSelector[]>(`api/article/getArticleSection/${this.articleSectionId}`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                res => {
                    this.sections = res;
                    this.setFilter();
                }
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    sectionChange(el: any): void {
        const id = parseInt(el.value, 10);
        if (el.checked && !this.selectedIds.find((s) => s == id)) {
            this.selectedIds.push(id);
            this.filterChanged.emit(this.selectedIds);
        } else if (!el.checked && this.selectedIds.find((s) => s == id)) {
            this.selectedIds = this.selectedIds.filter(s => s != id);
            this.filterChanged.emit(this.selectedIds);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedIds'] && changes['selectedIds'].currentValue) {
            this.setFilter();
        }
    }

    setFilter(): void {
        if (this.sections) {
            this.sections.forEach(s => {
                s.checked = this.selectedIds.find(el => el == s.articleId) != undefined;
            });
        }
    }
}