/**
 * Component providing functionality of a full article detail.
 */
import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ArticleMenuSectionSelector, Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
    templateUrl: '../../tpl/article-list-footer.html',
    selector: 'cmp-article-list-footer',
    styleUrls: ['../../../assets/styles/2-components/footer-links.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ArticleListFooterComponent extends Translatable {

    @Input() articleSectionIds: number[];

    articles: ArticleMenuSectionSelector[];
    body: string | SafeHtml;
    clmn: boolean[];
    maxRowIdx: number = 6 - 1;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, seSvc: SettingsService,
                private http: HttpClient,private sanitizer: DomSanitizer) {
        super(dataSvc, seSvc);
    }

    getArticles(): void {
        const qs = this.prepareQsFromArray();
        this.http.get<ArticleMenuSectionSelector[]>(`api/article/getFooterMenuLinks?${qs}`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (res) => {
                    this.articles = res;
                    this.clmn = this.articles.map(()=> false);
                }
            );
    }

    getContactData(): void {
        this.http.get<string>(`api/settings/contact-seting`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
                if (res) this.body = this.sanitizer.bypassSecurityTrustHtml(<string>(res));
            });
    }

    private prepareQsFromArray(): string {
        let result: string = ``;
        this.articleSectionIds.forEach((elem, index) => {
            result += index === 0 ? `sectionIds=${elem}` : `&sectionIds=${elem}`;
        });
        return result;
    }

    ngOnInit(): void {
        this.getArticles();
        this.getContactData();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    toggleColumn(i:number):void{
        if( i < this.clmn.length){
            this.clmn[i] = !this.clmn[i];
        }
    }
}