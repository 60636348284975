import {AfterViewInit, Component, ViewEncapsulation} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CookiesConsent, CookiesConsentGroupSelector, CookiesConsentStatus, CookiesConsentTexts} from "./common";
import {take, takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {CookiesConsentService} from "./cookies-consent.service";
import {Subject} from "rxjs";
import {GeneralDialogRef} from "../general-dialog/common";



@Component({
    selector: 'cmp-cookie-consent',
    templateUrl: '../../tpl/cookie-consent.html',
    styleUrls: ['../../../assets/styles/2-components/modal-cookies.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CookiesConsentComponent extends Translatable implements AfterViewInit {

    get showWarning(): boolean {
        return this.ccSvc.showWarning;
    }

    set showWarning(value: boolean) {
        this.ccSvc.showWarning = value;
    }

    private ngUnsubscribe: Subject<any> = new Subject<any>();

    form: FormGroup;
    cookiesConsentTexts: CookiesConsentTexts;

    activeDescription: string;
    activeGroupId: number;

    allGroups: Array<CookiesConsentGroupSelector>;

    showSettings: boolean = false;
    showCookies: boolean = true;
    showAbout: boolean = false;

    constructor(public dataSvc: DataService, public seSvc: SettingsService,
                private fb: FormBuilder, private http: HttpClient,
                public ccSvc: CookiesConsentService, private dialogRef: GeneralDialogRef) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
        this.ccSvc.dataLoadedEvent.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.allGroups = this.ccSvc.groups;
                this.makeActive(3);
            });

        this.ccSvc.getAllGroups();
        this.createForm();
        this.getTexts();
    }


    createForm(): void {
        this.form = this.fb.group({
            ad_storage: [this.ccSvc.isAdsConsent()],
            analytics_storage: [this.ccSvc.isAnalyticsConsent()],
            functionality_storage: [true],
            personalization_storage: [this.ccSvc.isPersonalizationConsent()],
            security_storage: [this.ccSvc.isSecurityConsent()]
        });
    }

    private getTexts() {
        this.http.get<CookiesConsentTexts>('api/cookies-consent/get-cookie-consent-texts')
            .pipe(take(1))
            .subscribe((res) => {
                this.cookiesConsentTexts = res;
            });
    }


    ngOnDestroy() {
        if (this.ngUnsubscribe) {
            this.ngUnsubscribe.next();
            this.ngUnsubscribe.complete();
        }
    }

    ngAfterViewInit(): void {
    }


    enableRequired() {
        const consent: CookiesConsent = new CookiesConsent();
        consent.date = new Date();
        consent.gtmConsent.setDefault();
        this.ccSvc.setConsent(consent);
        this.ccSvc.showWarning = false;
        this.patchForm();

        this.dialogRef.close();
    }

    enableSelected() {
        const consent: CookiesConsent = new CookiesConsent();
        consent.date = new Date();
        consent.gtmConsent.setDefault();
        consent.gtmConsent.ad_storage = this.form.controls.ad_storage.value ? CookiesConsentStatus.granted : CookiesConsentStatus.denied;
        consent.gtmConsent.ad_user_data = this.form.controls.ad_storage.value ? CookiesConsentStatus.granted : CookiesConsentStatus.denied;
        consent.gtmConsent.ad_personalization = this.form.controls.ad_storage.value ? CookiesConsentStatus.granted : CookiesConsentStatus.denied;
        consent.gtmConsent.personalization_storage = this.form.controls.personalization_storage.value ? CookiesConsentStatus.granted : CookiesConsentStatus.denied;
        consent.gtmConsent.analytics_storage = this.form.controls.analytics_storage.value ? CookiesConsentStatus.granted : CookiesConsentStatus.denied;
        consent.gtmConsent.security_storage = this.form.controls.security_storage.value ? CookiesConsentStatus.granted : CookiesConsentStatus.denied;
        this.ccSvc.setConsent(consent);
        this.ccSvc.showWarning = false;
        this.patchForm();

        this.dialogRef.close();
    }

    enableAll() {
        const consent: CookiesConsent = new CookiesConsent();
        consent.date = new Date();
        consent.gtmConsent.setAllGranted();
        this.ccSvc.setConsent(consent);
        this.ccSvc.showWarning = false;
        this.patchForm();
    }


    makeActive(groupId: number) {
        if (this.activeGroupId === groupId) {
            this.allGroups.find(x => x.id === groupId).isShown = false;
            this.activeGroupId = null;
            return;
        }

        this.activeGroupId = groupId;
        if (!this.allGroups) {
            return;
        }
        for (let i = 0; i < this.allGroups.length; i++) {
            const group = this.allGroups[i];
            if (group.id == groupId) {
                group.isShown = true;
                this.activeDescription = group.description;
            } else {
                group.isShown = false;
            }
        }
    }

    toggleSettings() {
        this.showSettings = !this.showSettings;
    }


    showContentCookies() {
        this.showCookies = true;
        this.showAbout = false;
    }

    showContentAbout() {
        this.showCookies = false;
        this.showAbout = true;
    }

    private patchForm() {
        const val = {
            ad_storage: this.ccSvc.isAdsConsent(),
            analytics_storage: this.ccSvc.isAnalyticsConsent(),
            functionality_storage: true,
            personalization_storage: this.ccSvc.isPersonalizationConsent(),
            security_storage: this.ccSvc.isSecurityConsent()
        };
        this.form.patchValue(val);
    }

    public isInForm(code: string): boolean {
        const a: string[] = [
            "ad_storage",
            "analytics_storage",
            "functionality_storage",
            "personalization_storage",
            "security_storage",
        ];

        const ret = a.includes(code);

        return ret;
    }
}