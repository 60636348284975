/**
 * Provides route guarding mechanism for secured routes
 * @description
 * If the user wants to navigate by any means to the route, which is secured by this guard
 * the guard either brings up the dialog with warning or it navigates to "you must login" route
 * https://angular.io/docs/ts/latest/api/router/index/CanActivate-interface.html
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {CredentialStorage} from "./credential-storage.service";
import {AuthInfo} from "../interfaces/general";

@Injectable()
export class RouteGuardService {

    dummy: any;

    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        this.dummy = route;//just to disable "'route' is declared but its value is never read" compile-time error

        let fwd = state.url;
        let url = fwd ? `/upozorneni?fwd=${fwd}` : '/upozorneni';

        let canGo: boolean = CredentialStorage.authInfo != null;
        let ai: AuthInfo = CredentialStorage.authInfo;

        if (canGo && ai) {
            if (!CredentialStorage.isAiTimeValid()) {
                canGo = false;
            }
        }

        if (!canGo) {
            this.router.navigateByUrl(url)
                .then(() => {
                    return false
                });
        } else return true;
    }

}