import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {IAresDetail} from "../interfaces/general";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AresService {

    constructor(private http: HttpClient) {

    }

    public getAresSubject(ic: string) : Observable<IAresDetail> {
        return this.http.get<IAresDetail>(`api/ares/subject/${ic}`);

    }
}