import {BcInfo, IProductList, StringIndexedObject} from "../../interfaces/general";
import {Parameter} from "../category/interfaces";

export type ScrollDetailItemType = 'description' | 'parameters' | 'related' | 'alternatives' | 'action' | 'reviews' | 'download' | 'videos' | 'variants';

export interface IProductImage {
    Path: string;
    IsMainImage: boolean;
}

export interface IProductFile {
    CultureId: number;
    FileName: string;
    FilePath: string;
}

export interface IProductForComparison extends IProduct {
    parameters: Parameter[]
}

export interface IProductUnits {
    basicUnit: string;
    unit: string;
    coefficient: number;
    recalculated: number;
    unitPrice: number;
}

export interface IPriceInfo {
    ProductId: number;
    PriceWithVAT: number;
    PriceWithoutVAT: number;
    BasePrice: number;
}

export interface IProduct extends IProductList {
    ProducerImage?: string;
    SeoTitle?: string;
    SeoDescription?: string;
    SeoKeywords?: string;
    Warranty?: string;
    Rating?: RatingSelector;
    ProductCategoryNamePath?: string;
    Images: IProductImage[];
    Files: IProductFile[];
    ProductCategoryBreadcrumbInfo: BcInfo[];
    CategoryIds: number[];
    Units: IProductUnits[];
    Gifts: IProductGift[];
    aprovedGifts: IProductGift[];
}

export interface DetailConfig extends StringIndexedObject {
    selectedTab: number;
}

export interface RatingSelector {
    totalRatings: number;
    rating: number;
    alreadyRated: boolean;
    ratingPercent?: number;
    stars?: number;
}

export interface ParameterProductSelector {
    name: string;
    value: string;
}

export interface ParameterGroupSelector {
    groupName?: any;
    parameters: ParameterProductSelector[];
}

export interface ReviewItemSelector {
    message: string;
    negative: boolean;
    id?: number;
    reviewId?: number;
}

export interface ReviewRequest {
    inputRating: number;
    reviewItems?: ReviewItemSelector[];
    anonymous?: boolean;
    productId: number;
    message: string;
}

export interface ReviewSelector {
    userDisplayName?: string;
    isMyReview: boolean;
    productId: number;
    message: string;
    positiveReviewItems?: ReviewItemSelector[];
    negativeReviewItems?: ReviewItemSelector[];
    dateInserted?: any;
    outputRating?: number;
    outputRatingPercent?: number;
    anonymous: boolean;
    avatarPath: string;
    grAvatarUrl: string;
    preferGrAvatar: boolean;
    isApproved: boolean;
    reviewReaction?: ReviewReactionSelector[];
}

export interface ReviewRatingRequest {
    rating: RatingSelector;
    reviews: ReviewSelector[];
}

export interface ReviewRatingSelector {
    alreadyRated: boolean;
    reviews: ReviewSelector[];
}


export interface ReviewReactionSelector {
    id: number;
    reviewId: number;
    adminUserId: number;
    adminUserName: string;
    message: string;
    dateInserted?: any;

}

export interface MagnificPopupItem {
    src: string;
}


export interface SopcialPluginSettings {
    isFacebook: boolean;
    isGooglePlus: boolean;
    isTwitter: boolean;
    fbApiKey: string;
    fbLink: string;
    igLink: string;
    fbURL: string;
}

export interface PreviewImageChanged {
    index: number;
    path: string
}

export interface IHoliday {
    date: any;
    name: string;
}

export interface IProductAvail {
    id: number;
    displayLimit: number;
    displayType: number;

    warehouseInfo: IWarehouseAvail[];
}

export interface IWarehouseAvail {
    displayName: string;
    displayLimit: number;
    displayType: number;
    onStock: number;
}

export interface IProductGift {
    fromCount: number;
    productId: number;
    gift: IProductList;
}

export interface IProductDiscount {
    amountFrom: number;
    currencyId: number;
    priceWithVat: number;
    priceWithoutVat: number;
}

export enum RenderStyle {
    Detail = 'Detail',
    Row = 'Row',
    Box = 'Box',
    Table = 'Table',
    TableDPH = 'TableDPH',
    Whisper = 'Whisper',
    Favourite = 'Favourite',
    Cart = 'Cart'
}

export enum PriceTypes {
    BasePrice,
    BasePriceAction,
    MainPrice,
    MainPriceAction,
    MainPriceVariants,
    CommonPrice,
    CommonPriceAction,
    CommonPriceVariants,
}