import {AfterViewInit, Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {DataService} from "../../services/data.service";
import {
    CategoryItemSelector,
    Culture,
    IWishList,
    SearchRequest,
    SearchSelector,
    Translatable
} from "../../interfaces/general";
import {LoginComponent} from "../login/login.component";
import {SettingsService} from "../../services/settings.service";
import {DigestService} from "../../services/digest.service";
import {NavigationStart, Router} from "@angular/router";
import {loadFromLocalStorage, loadFromSession} from "../../helpers/cookie.helper";
import {SharedAppSettings} from "../../shared-settings/shared-settings";
import {WishListService} from "../../services/wish-list-service";
import {CompareProductsService} from "../../services/compare-products.service";
import {debounceTime, filter, map, switchMap, takeUntil} from 'rxjs/operators';
import {fromEvent, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {CartService} from "../cart/cart.service";
import {SopcialPluginSettings} from "../product/common";
import {SmartsuppService} from "../../services/smartsupp.service";
import {CredentialStorage} from "../../services/credential-storage.service";
import {IUnpaidInvoice} from "../index/common";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {GeneralDialogService} from "../general-dialog/general-dialog.service";
import {PopupB2bComponent} from "../popup-b2b/popup-b2b.component";
import {CookiesConsentService} from "../cookies-consent/cookies-consent.service";
import {ComponentRef} from '@angular/core/src/render3';

declare let $: any;
declare let moment: any;

@Component({
    selector: 'app',
    templateUrl: '../../tpl/app.html',
    styleUrls: ['../../../assets/styles/layout.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AppComponent extends Translatable implements AfterViewInit {

    @ViewChild(LoginComponent) loginComponent: LoginComponent;
    @ViewChild('headerSticky') headerSticky: ElementRef;
    @ViewChild('menuInner') menuInner: ElementRef;
    @ViewChild('headerWrapper') headerWrapper: ElementRef;

    categories: CategoryItemSelector[];
    cultureOptions: Culture[];
    selectedCulture: Culture;
    menuImagePathPrefix: string;
    tim: any;
    menuVisible: boolean = false;
    suggestVisible: boolean = false;
    searchBoxVisible: boolean = false;
    suggestResult: SearchSelector;
    responseMenuDisplay: boolean = false;
    menuArticleId: number;
    footerArticleIds: number[];
    headerArticleIds: number[];
    wishLists: IWishList[];
    fbLink:string;
    igLink:string;
    homepage: boolean = true;

    categoryImagePathPrefix: string;

    showNewsLetterModal: boolean = false;

    ngUnsubscribe: Subject<any> = new Subject<any>();
    enterFired: Subject<any> = new Subject<any>();

    showUnpaidInvoices: boolean = true;
    unpaidInvoices: IUnpaidInvoice[] = [];

    searchToggled: boolean = false;
    lastWindowWidth: number;

    public componentRef: ComponentRef<any>;


    get headerWrapperHeight(): number {
        return this.headerWrapper.nativeElement.offsetHeight;
    }

    get responsiveWidth(): number {
        return window.innerWidth;
    }

    constructor(public dataSvc: DataService, public seSvc: SettingsService, public digestSvc: DigestService,
                private router: Router, private saSvc: SharedAppSettings, private wishListSvc: WishListService,
                public compareSvc:CompareProductsService, private http: HttpClient, public cartSvc: CartService,
                public smartsupp: SmartsuppService, private dialogSvc: GeneralDialogService, public ccSvc: CookiesConsentService,
                private rend: Renderer2
    ) {
        super(dataSvc, seSvc);
        this.categoryImagePathPrefix = seSvc.settings.imageServerPathPrefix + '/fotocache/suggest/images/cathome/';

        this.wishLists = wishListSvc.wishLists;
        this.wishListSvc.onWishListsChanged.subscribe((res: IWishList[]) => {
            this.wishLists = res;
        });

        this.menuArticleId = this.saSvc.Defaults.MenuArticleId;
        this.footerArticleIds = this.saSvc.Defaults.FooterArticleIds
            .split(';')
            .map(a => { return parseInt(a) });

        this.headerArticleIds = this.saSvc.Defaults.HeaderArticleIds
            .split(';')
            .map(a => { return parseInt(a) });
        this.menuImagePathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/cattop/';

        this.cultureOptions = seSvc.settings.cultures;
        this.selectedCulture = this.seSvc.culture;

        this.router.events
            .pipe(
                filter((evt: any) => evt instanceof NavigationStart),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(res => {
                /**
                 * Ugly hack for not letting unauthorized users in on published DEV servers
                 */
                if (this.seSvc.settings.preAuth) {

                    if (res['url'].toLowerCase() != '/pa') {

                        let pa = this.seSvc.settings.preAuthToLocal ? loadFromLocalStorage('pa') : loadFromSession('pa');

                        let mt = moment();
                        let mtValidTo = pa ? moment(pa.validTo) : 0;

                        if (!pa || mt > mtValidTo) {
                            this.router.navigateByUrl('/pa').then(() => {
                                this.seSvc.showPopUpB2B = false;
                            })
                        }
                    }
                }
                if (/registrace/.test(res['url']) && this.userLoggedIn) {
                    this.router.navigateByUrl('/').then(() => {
                    });
                }

                this.menuVisible = false;
        });

        $('body').addClass(this.seSvc.domainInfo.b2b ? 'b2b' : 'b2c');

        CredentialStorage.loginExpired
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.digestSvc.logOut();
            });
    }

    showDialogB2B(){
        const config: GeneralDialogConfig<void> = {
            data: null,
            cssClassModifier: '',
            isCloseAble: true,
            title: null
        };
//        <cmp-popup-b2b *ngIf="seSvc.showPopUpB2B && !seSvc.visitedPopUpB2B"></cmp-popup-b2b>
        const dialogRef = this.dialogSvc.open(PopupB2bComponent, config);
            dialogRef.afterClosed.subscribe(() => {
        });
    }
    setCulture(id: number): void {
        this.selectedCulture = this.cultureOptions.filter(c => c.cultureId == id)[0];
        this.seSvc.setCultureAndReload(true, id);
    }

    getCategories(): void {
        this.http.get<CategoryItemSelector[]>(`api/category`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.categories = res;
            });
    }

    getSocialMediaSettings() {
        this.http.get<SopcialPluginSettings>(`api/settings/sharingSetings`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                if (res) {
                    this.fbLink = res['fbLink'];
                    this.igLink = res['igLink'];
                }
            });
    }

    getSuggest(phrase: string): any {
        let request: SearchRequest = {
            phrase: decodeURI(phrase),
            pageIndex: 1,
            pageSize: 6,
            forSuggest: true
        };
        return this.http.post(`api/search`, request);
    }

    ngOnInit(): void {
        this.getCategories();
        this.getSocialMediaSettings();
        this.getUnpaidInvoices();

        let baseUrl = location.href.replace($("base")[0].href, "").length == 0;
        this.seSvc.showPopUpB2B = baseUrl && this.seSvc.domainInfo.b2b && this.seSvc.domainInfo.otherDomain && !this.userLoggedIn;
        if (!this.seSvc.visitedPopUpB2B && (this.userLoggedIn || !baseUrl)) {
            this.seSvc.visitedPopUpB2B = true;
        }
    }



    ngAfterViewInit(): void {
       /* if(this.seSvc.showPopUpB2B && !this.seSvc.visitedPopUpB2B)
            this.showDialogB2B();*/
        this.bindSuggest();
        addEventListener('click', () => {
            this.suggestVisible = false;
            const html = document.querySelector('html');
            this.rend.removeClass(html, 'locked');
        });

        this.smartsupp.init();
    }

    get menuMaxHeight() {
        return window.innerHeight - this.headerWrapper.nativeElement.offsetHeight;
    }


    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.enterFired.complete();

        const html = document.querySelector('html');
        this.rend.removeClass(html, 'locked');
        if(this.componentRef) {
            this.componentRef.destroy();
        }

        clearTimeout(this.tim);
    }

    isMenuVisible(value: boolean): void {
        this.menuVisible = value;
    }

    private bindSuggest(): void {
        const searchBox = document.getElementById('js-search');
        fromEvent(searchBox, 'input')
            .pipe(
                map((e: any) => e.target.value),
                filter(text => text.length > 2),
                debounceTime(333),
                switchMap((s: string) => this.getSuggest(s)),
                takeUntil(this.enterFired),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((res: SearchSelector) => {
                this.suggestResult = res;
                this.suggestVisible = true;
                const html = document.querySelector('html');
                this.rend.addClass(html, 'locked');
            });
    }

    searchKeyUp(evt: KeyboardEvent) {
        if (evt.key === 'Escape') {
            this.suggestVisible = false;
            const html = document.querySelector('html');
            this.rend.removeClass(html, 'locked');
            if (this.responsiveWidth <= 990){
                this.toggleSearch(true);
            }
        }
    }

    search(phrase: string): void {
        if (phrase.length == 0) return;
        $('.js-search').val('');
        this.suggestVisible = false;
        if (this.responsiveWidth <= 990) {
            this.toggleSearch(false);
        }
        this.router.navigateByUrl(`/vysledky-vyhledavani?q=${phrase}`).then(() => {
        })
    }

    searchKeyPress(evt: KeyboardEvent, phrase: string): void {
        if (evt.key === 'Enter' || evt.key=='NumpadEnter') {
            this.search(phrase);
            this.suggestVisible = false;
            const html = document.querySelector('html');
            this.rend.removeClass(html, 'locked');
            this.enterFired.next();
            this.bindSuggest();
            return;
        }
    }

    onResize(){
        let js = $('.js-search-toggle');

        if (this.responsiveWidth > 990) {
            js.slideToggle(0,function(){
                var final_state = $(this).is(':hidden') ? 'hidden' : 'visible';
                if (final_state === 'hidden'){
                    $(this).slideToggle(0);
                }
            });

        } else if (this.responsiveWidth <= 990 && !js.is(':hidden') && this.lastWindowWidth !== this.responsiveWidth && this.lastWindowWidth !== undefined){
            js.slideToggle(0);
        }

        this.lastWindowWidth = this.responsiveWidth;

    }

    toggleSuggest(event: any): void {
        console.log(event.target);
    }

    toggleSearch(checkMobile?: boolean): void {
        let js = $('.js-search-toggle');
        if (checkMobile === true && this.responsiveWidth <= 990) {
            js.slideDown();
            return;
        } else if (checkMobile === false && this.responsiveWidth <= 990) {
            js.slideUp();
            return;
        }

        js.slideToggle(0);
        this.toggleMenu(false);
    }

    toggleMenu(value?: boolean): void {
        if ( $('.js-menu').hasClass('menu--is-active') || value === false ){
            $('.js-menu').removeClass('menu--is-active');
            this.menuVisible = false;
            if (this.responsiveWidth <= 990){
                const html = document.querySelector('html');
                this.rend.removeClass(html, 'locked');
            }
        } else {
            $('.js-menu').addClass('menu--is-active');
            this.menuVisible = true;
            this.toggleSearch(false);
            if (this.responsiveWidth <= 990){
                const html = document.querySelector('html');
                this.rend.addClass(html, 'locked');
            }
        }
    }

    menuMouseEnter(): void {
        if (this.categories && this.categories.length > 0 && this.responsiveWidth >= 990) {
            this.menuVisible = true;
            $('.js-menu').addClass('menu--is-active');
        }
    }

    menuMouseLeave(): void {
        if (this.responsiveWidth >= 990) {
            this.menuVisible = false;
            $('.js-menu').removeClass('menu--is-active');
        }
    }

    navigateSuperCategory(): void {
        this.router.navigateByUrl('/kategorie').then(() => {});
        this.menuVisible = false;
        $('.js-menu').removeClass('menu--is-active');
    }

    setShowUnpaidInvoices(event: boolean): void {
        this.showUnpaidInvoices = event;
        setTimeout(() => { }, 300);
    }

    getUnpaidInvoices(): void {
        this.http.get('api/user/unpaidInvoices')
            .subscribe((res: IUnpaidInvoice[]) => {
                this.unpaidInvoices = res;
            });
    }
}
