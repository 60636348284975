import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {Country, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AddressType, InvoiceAddressSelector} from "./common";
import {UserExistsRequestModel, UserExistsResultModel} from "../register-b2c/common";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";

@Component({
    selector: 'cmp-address-invoice',
    templateUrl: '../../tpl/address-invoice.html'
})

export class AddressInvoiceComponent extends Translatable implements OnChanges {

    @Input() id: string;
    @Input() cssClassPrefix: string = 'user-data';
    @Input() addressType: AddressType;
    @Input() address: InvoiceAddressSelector;
    @Input() readOnly: boolean;
    @Input() aresLock: boolean = false;
    @Input() withContact: boolean = true;
    @Input() registration: boolean = false;
    @Output() changes: EventEmitter<InvoiceAddressSelector> = new EventEmitter<InvoiceAddressSelector>();
    @Output() userExists: EventEmitter<boolean> = new EventEmitter<boolean>();

    addressForm: FormGroup;
    emailPattern: any;
    phonePattern: any;
    zipCodePattern: any;
    countries: Country[];
    emailExists: boolean = false;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, public seSvc: SettingsService, private fb: FormBuilder,
                private http: HttpClient) {
        super(dataSvc, seSvc);

        this.countries = seSvc.settings.countries;
        this.emailPattern = this.seSvc.settings.validationPatterns.email;
        this.phonePattern = this.seSvc.settings.validationPatterns.phone[this.seSvc.culture.cultureId];
        this.zipCodePattern = this.seSvc.settings.validationPatterns.zipCodeCz;

        this.createForm();
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes['address']) {
            this.address = {...this.emptyModel(), ...changes['address'].currentValue};
            this.addressForm.patchValue(this.address, {onlySelf: true, emitEvent: false});
        }
        if(changes['withContact']){
            if (this.withContact) {
                this.addressForm.controls['phone'].setValidators(Validators.required);
                this.addressForm.controls['email'].setValidators(Validators.required);
            }
            else {
                this.addressForm.controls['phone'].setValidators(null);
                this.addressForm.controls['email'].setValidators(null);
            }
            this.addressForm.updateValueAndValidity();
        }
    }

    getAddress(): InvoiceAddressSelector {
        let cid = +this.addressForm.get('countryId').value;
        let country = cid > 0 ? this.countries.filter(f => f.id === cid)[0].name : null;
        return {
            id: this.addressForm.get('id').value,
            countryId: cid,
            country: country,
            addressType: this.addressType,
            city: this.addressForm.get('city').value,
            firstName: this.addressForm.get('firstName').value,
            lastName: this.addressForm.get('lastName').value,
            street: this.addressForm.get('street').value,
            zipCode: this.addressForm.get('zipCode').value,
            disabled: false,
            writtenInAccountancy: false,
            email: this.addressForm.get('email').value,
            phone: this.addressForm.get('phone').value
        };
    }

    forceDirty(): void {
        for (let obj in this.addressForm.controls) {
            (this.addressForm.controls[obj] as AbstractControl).markAsDirty();
        }
    }

    private createForm(): void {

        if (!this.address) {
            this.address = this.emptyModel();
        }

        this.addressForm = this.fb.group({
                id: [this.address.id, [Validators.required]],
                firstName: [{
                    value: this.address.firstName,
                    disabled: false
                }, [Validators.required, Validators.maxLength(50)]],
                lastName: [{
                    value: this.address.lastName,
                    disabled: false
                }, [Validators.required, Validators.maxLength(100)]],
                street: [{value: this.address.street, disabled: false}, [Validators.required, Validators.maxLength(100)]],
                city: [{value: this.address.city, disabled: false}, [Validators.required, Validators.maxLength(100)]],
                zipCode: [{
                    value: this.address.zipCode,
                    disabled: false
                }, [Validators.required, Validators.pattern(this.zipCodePattern)]],
                countryId: [{value: this.address.countryId, disabled: false}, [Validators.required]],
                email: [this.address.email, [Validators.required, Validators.pattern(this.emailPattern)]],
                phone: [this.address.phone, [Validators.required, Validators.pattern(this.phonePattern)]],
            }
        );

        if (this.isUserB2B) {
            this.addressForm.get('firstName').clearValidators();
            this.addressForm.get('lastName').clearValidators();
        }


        this.addressForm.valueChanges.subscribe(() => {
            this.changes.emit(this.getAddress());
        });

        this.addressForm.get('email').valueChanges.subscribe((val) => {
            if (!this.readOnly) this.validateEmail(val);
        });
    }
    emptyModel(): InvoiceAddressSelector {
        return {
            id: 0,
            countryId: this.countries[0].id,
            addressType: this.addressType,
            city: '',
            firstName: '',
            lastName: '',
            street: '',
            zipCode: '',
            disabled: false,
            writtenInAccountancy: false,
            email: '',
            phone: ''
        };
    }

    validateEmail(userName: string): void {
        let request: UserExistsRequestModel = {userName: userName};
        this.http.post('api/user/exists', request)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: UserExistsResultModel) => {
                this.emailExists = res.exists;
                this.userExists.emit(res.exists);
            })
    }

}