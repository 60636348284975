import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ParcelShopsRequest} from "../cart/common";

@Injectable()
export class DeliveryPaymentService {

    constructor(private http: HttpClient) {
    }

    getDeliveries(cartPrice:number, b2b: boolean): any {
        let url: string = 'api/deliverypayment/getdeliveries';
        return this.http.post(url, {cartPrice: cartPrice, b2b: b2b});
    }

    getPayments(cartPrice:number): any {
        let url: string = 'api/deliverypayment/getpayments';
        return this.http.post(url, {cartPrice: cartPrice});
    }

    getRelations(): any {
        let url: string = 'api/deliverypayment/getrelations';
        return this.http.get(url);
    }

    getDeliveryFreeFrom(b2b: boolean) {
        return  this.http.post('api/deliverypayment/delivery-free-from', {b2b: b2b});
    }

    getDeliveryFreeFromGlobal(b2b: boolean) {
        return  this.http.get('api/settings/delivery-free-from/' + b2b);
    }

    pplInformation(request: ParcelShopsRequest):any{
        let url = `api/deliverypayment/get-ppl-filter` ;
        return this.http.post(url, request);
    }

    CpPobockaInformation(request: ParcelShopsRequest):any{
        let url = `api/deliverypayment/get-cppo-filter` ;
        return this.http.post(url, request);
    }

    ZasilkovnaInformationOne(id: number):any{
        let url = 'api/deliverypayment/get-zasilkovna/' + id;
        return this.http.get(url);
    }

    ZasilkovnaApi():any{
        let url = 'api/deliverypayment/get-zasilkovna-api' ;
        return this.http.get(url);
    }
}
