/**
 * Created by petr.humplik on 20.06.2017.
 */
import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {LastComponent} from "./last.component";

@NgModule({
    imports: [CommonModule],
    declarations: [LastComponent],
    exports: [LastComponent]
})

export class HelperComponentsModule {}
