import {EventEmitter, Injectable} from "@angular/core";
import {loadFromLocalStorage, removeFromLocalStorage, saveToLocalStorage} from "../helpers/cookie.helper";
import {AuthInfo} from "../interfaces/general";

declare let sha256: any;
declare let StringView: any;
declare let moment: any;

@Injectable()
export class CredentialStorage {

    static loginExpired: EventEmitter<any> = new EventEmitter();

    private _permanent = false;
    get permanent(): boolean {
        return this._permanent;
    }
    set permanent(value: boolean) {
        this._permanent = value;
        if (value) {
            localStorage.setItem('p', 'true');
        } else {
            localStorage.setItem('p', 'false');
        }
    }

    static get userLoggedIn(): boolean {
        return CredentialStorage.isAiTimeValid();
    }

    static get isUserB2B(): boolean{
        let ai: AuthInfo = CredentialStorage.authInfo;
        return ai? ai.isB2B : false;
    }

    static get isUserAdmin(): boolean{
        let ai: AuthInfo = CredentialStorage.authInfo;
        return ai? ai.isAdmin : false;
    }
    static get companyId(): number{
        let ai: AuthInfo = CredentialStorage.authInfo;
        if (ai && ai.companyId > 0){
            return ai.companyId;
        }
        return -1;
    }
    static get authInfo(): AuthInfo {
        try {
            let b64Ai: string = loadFromLocalStorage('ai');
            let ais: AuthInfo = JSON.parse(StringView.makeFromBase64(b64Ai));
            if (ais) {
                if (moment(ais.validTo) < moment()) {
                    CredentialStorage.removeAuthInfo();
                    CredentialStorage.loginExpired.emit();
                    return null;
                }
                return ais;
            } else return null;
        }
        catch (e) {
            CredentialStorage.removeAuthInfo();
            return null;
        }
    }

    static set authInfo(value: AuthInfo) {
        let b64Ai = new StringView(JSON.stringify(value)).toBase64();
        saveToLocalStorage('ai', b64Ai);
    }

    constructor () {
        let s = localStorage.getItem('p');
        if (s && s == 'true') {
            this._permanent = true;
        }
    }

    static removeAuthInfo() {
        removeFromLocalStorage('ai');
        saveToLocalStorage('p', 'false');
    }

    /**
     * @description
     * Computes the HA1 component of the Authorization header. The computation receipt is as follows:
     * sha256 (username:realm:password)
     * @param userName
     * @param realm
     * @param password
     * @returns {string} containing sha256 hash
     */
    static ha1(userName: string, realm: string, password: string): string {
        return sha256(`${userName}:${realm}:${password}`);
    }

    /**
     * @description
     * Computes the HA2 component of the Authorization header. The computation receipt is as follows:
     * sha256 (method:uri)
     * @param requestedMethod
     * @param requestedUri
     * @returns {string} containing sha256 hash
     */
    static ha2(requestedMethod: string, requestedUri: string): string {
        return sha256(`${requestedMethod}:${requestedUri}`);
    }

    private static getFunctionValue(x: number, a: number, b: number, c: number): number {
        return Math.floor(a * Math.pow(x, 2) + b * x + c);
    }

    static getStoredAuthHeadersBody(reqUri: string, reqMethod: string): string {

        let ai: AuthInfo = CredentialStorage.authInfo;

        if (!ai) {
            return undefined;
        }

        const x = Math.floor(Math.random() * 100);
        const y = CredentialStorage.getFunctionValue(x, ai.a, ai.b, ai.c);

        let HA1 = ai.hu;
        let HA2 = CredentialStorage.ha2(reqMethod, reqUri);

        let response = sha256(`${HA1}:${ai.nonce}:${x}:${y}:${ai.clientNonce}:auth:${HA2}`);
        return `Digest username="${ai.userName}", realm="${ai.realm}", nonce="${ai.nonce}", uri="${reqUri}", qop="auth", x=${x}, cnonce="${ai.clientNonce}", response="${response}"`;
    }

    static isAiTimeValid(): boolean {
        let mt = moment();
        let ai: AuthInfo = CredentialStorage.authInfo;
        if (!ai) return false;

        let mtValidTo = moment(ai.validTo);
        let valid = mtValidTo > mt;
        if (!valid) {
            CredentialStorage.loginExpired.emit();
        }
        return valid;
    }
}